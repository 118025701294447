import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2058 1974" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >


<path fill="#47AC3F" opacity="1.000000" stroke="none" 
	d="
M1058.000000,0.999989 
	C1061.692871,1.000000 1065.385864,1.000000 1069.755615,1.373945 
	C1088.216431,2.882420 1106.030273,3.674257 1123.779053,5.214501 
	C1181.443359,10.218626 1238.385376,19.674833 1294.451050,34.117615 
	C1465.328857,78.136528 1616.326782,159.071686 1745.646118,279.302704 
	C1876.193359,400.675079 1968.195679,546.500366 2019.567871,717.509033 
	C2036.568726,774.101624 2047.881470,831.878479 2053.729004,890.668701 
	C2055.738281,910.870239 2056.515381,931.194336 2057.831543,951.898987 
	C2057.825928,976.085266 2057.844727,999.835022 2057.935791,1024.069580 
	C2056.653564,1059.866089 2053.341309,1094.986572 2048.115723,1129.942261 
	C2037.365601,1201.852173 2018.764404,1271.676270 1991.649170,1339.109253 
	C1918.922119,1519.974976 1802.226196,1666.565552 1643.926636,1779.637939 
	C1477.747437,1898.338745 1291.787231,1961.647827 1088.046875,1973.220093 
	C1082.017090,1973.562500 1076.015015,1974.395630 1070.000000,1975.000000 
	C1065.975464,1975.000000 1061.950928,1975.000000 1057.126221,1974.754883 
	C1055.361206,1974.316406 1054.396729,1973.957031 1053.431396,1973.954468 
	C1037.696045,1973.914185 1021.960388,1973.891113 1006.225220,1973.960815 
	C1004.815369,1973.967041 1003.408386,1974.637939 1002.000000,1975.000000 
	C997.975464,1975.000000 993.950928,1975.000000 989.235962,1974.636108 
	C965.630005,1972.565308 942.675659,1971.263916 919.806519,1969.073364 
	C858.171326,1963.169434 797.554138,1951.592529 738.062683,1934.484741 
	C553.462585,1881.399658 394.391022,1785.988647 263.090027,1645.503174 
	C151.133911,1525.716064 73.655807,1386.523438 31.884571,1227.737549 
	C18.725737,1177.716675 9.943415,1126.907837 5.273834,1075.417236 
	C3.680709,1057.850220 3.152769,1040.186523 2.163361,1022.129150 
	C2.171876,998.939148 2.155172,976.187012 2.069839,952.946899 
	C3.414346,909.446899 7.927627,866.742737 15.332508,824.369141 
	C30.638075,736.784607 57.508682,652.864136 96.477600,572.937012 
	C172.332443,417.355164 281.795349,290.230591 423.392883,191.123489 
	C591.466309,73.485374 778.788574,11.843352 983.458557,2.099522 
	C985.318909,2.010955 987.153442,1.378517 989.000000,0.999999 
	C992.692932,1.000000 996.385803,1.000000 1000.873047,1.250836 
	C1002.466309,1.691002 1003.264954,2.043807 1004.064392,2.045578 
	C1020.801086,2.082661 1037.537964,2.103073 1054.274536,2.038360 
	C1055.517700,2.033554 1056.758179,1.361721 1058.000000,0.999989 
M1569.325317,259.158386 
	C1531.600952,233.662643 1492.320557,210.848160 1451.319580,191.043411 
	C1328.917480,131.919189 1199.734985,100.110016 1063.934692,94.779495 
	C996.595764,92.136253 929.707031,96.338211 863.264954,107.349854 
	C795.047424,118.655754 728.660706,136.793137 664.534851,162.747589 
	C473.063690,240.243988 320.938232,365.052612 212.453171,541.419006 
	C142.384827,655.330444 101.659210,779.386536 89.269470,912.579224 
	C83.914658,970.144775 84.544022,1027.721191 90.805565,1085.238647 
	C97.735092,1148.892334 111.518196,1210.999878 132.387680,1271.509277 
	C170.498886,1382.010010 228.527283,1480.967651 305.857422,1568.528564 
	C409.679779,1686.086792 535.568359,1771.755737 682.168945,1827.043579 
	C781.164185,1864.377930 883.686829,1884.345581 989.404724,1888.250488 
	C1065.497314,1891.061157 1140.899170,1885.221436 1215.598511,1870.634399 
	C1293.728271,1855.377441 1368.964844,1831.046021 1440.872681,1796.841553 
	C1604.092529,1719.202637 1735.771362,1605.398438 1832.397339,1452.260376 
	C1912.870605,1324.721924 1956.844971,1185.253174 1964.701294,1034.594360 
	C1968.132812,968.788818 1963.820679,903.408875 1952.109619,838.522278 
	C1933.091919,733.150330 1895.680420,634.740051 1840.219727,543.222656 
	C1770.928711,428.883636 1680.211426,334.879883 1569.325317,259.158386 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M1070.468628,1975.000000 
	C1076.015015,1974.395630 1082.017090,1973.562500 1088.046875,1973.220093 
	C1291.787231,1961.647827 1477.747437,1898.338745 1643.926636,1779.637939 
	C1802.226196,1666.565552 1918.922119,1519.974976 1991.649170,1339.109253 
	C2018.764404,1271.676270 2037.365601,1201.852173 2048.115723,1129.942261 
	C2053.341309,1094.986572 2056.653564,1059.866089 2058.291504,1023.978027 
	C2058.716553,1023.601135 2058.858154,1023.800598 2059.000000,1024.000000 
	C2059.000000,1340.928589 2059.000000,1657.857056 2059.000000,1975.000000 
	C1729.645752,1975.000000 1400.291504,1975.000000 1070.468628,1975.000000 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M2.138141,1022.567078 
	C3.152769,1040.186523 3.680709,1057.850220 5.273834,1075.417236 
	C9.943415,1126.907837 18.725737,1177.716675 31.884571,1227.737549 
	C73.655807,1386.523438 151.133911,1525.716064 263.090027,1645.503174 
	C394.391022,1785.988647 553.462585,1881.399658 738.062683,1934.484741 
	C797.554138,1951.592529 858.171326,1963.169434 919.806519,1969.073364 
	C942.675659,1971.263916 965.630005,1972.565308 988.772705,1974.636108 
	C659.740906,1975.000000 330.481812,1975.000000 1.000000,1975.000000 
	C1.000000,1657.979126 1.000000,1340.958130 1.213242,1023.172974 
	C1.663703,1022.461548 1.900922,1022.514343 2.138141,1022.567078 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M988.531372,0.999999 
	C987.153442,1.378517 985.318909,2.010955 983.458557,2.099522 
	C778.788574,11.843352 591.466309,73.485374 423.392883,191.123489 
	C281.795349,290.230591 172.332443,417.355164 96.477600,572.937012 
	C57.508682,652.864136 30.638075,736.784607 15.332508,824.369141 
	C7.927627,866.742737 3.414346,909.446899 1.713761,953.025391 
	C1.284207,953.394531 1.142104,953.197266 1.000000,953.000000 
	C1.000000,635.738220 1.000000,318.476410 1.000000,1.000000 
	C330.020874,1.000000 659.041748,1.000000 988.531372,0.999999 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M2057.855469,951.462402 
	C2056.515381,931.194336 2055.738281,910.870239 2053.729004,890.668701 
	C2047.881470,831.878479 2036.568726,774.101624 2019.567871,717.509033 
	C1968.195679,546.500366 1876.193359,400.675079 1745.646118,279.302704 
	C1616.326782,159.071686 1465.328857,78.136528 1294.451050,34.117615 
	C1238.385376,19.674833 1181.443359,10.218626 1123.779053,5.214501 
	C1106.030273,3.674257 1088.216431,2.882420 1070.216187,1.373945 
	C1399.592285,1.000000 1729.184570,1.000000 2059.000000,1.000000 
	C2059.000000,317.354187 2059.000000,633.708435 2058.792480,950.841553 
	C2058.341797,951.567688 2058.098633,951.515015 2057.855469,951.462402 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M2057.831299,951.898987 
	C2058.098633,951.515015 2058.341797,951.567688 2058.792480,951.310181 
	C2059.000000,975.020874 2059.000000,999.041809 2059.000000,1023.531372 
	C2058.858154,1023.800598 2058.716553,1023.601135 2058.219238,1023.493164 
	C2057.844727,999.835022 2057.825928,976.085266 2057.831299,951.898987 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M1.000000,953.468628 
	C1.142104,953.197266 1.284207,953.394531 1.782389,953.513367 
	C2.155172,976.187012 2.171876,998.939148 2.163361,1022.129150 
	C1.900922,1022.514343 1.663703,1022.461548 1.213242,1022.704346 
	C1.000000,999.979126 1.000000,976.958191 1.000000,953.468628 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M1057.531372,0.999989 
	C1056.758179,1.361721 1055.517700,2.033554 1054.274536,2.038360 
	C1037.537964,2.103073 1020.801086,2.082661 1004.064392,2.045578 
	C1003.264954,2.043807 1002.466309,1.691002 1001.333618,1.250836 
	C1019.687561,1.000000 1038.375122,1.000000 1057.531372,0.999989 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M1002.468689,1975.000000 
	C1003.408386,1974.637939 1004.815369,1973.967041 1006.225220,1973.960815 
	C1021.960388,1973.891113 1037.696045,1973.914185 1053.431396,1973.954468 
	C1054.396729,1973.957031 1055.361206,1974.316406 1056.663086,1974.754883 
	C1038.979126,1975.000000 1020.958191,1975.000000 1002.468689,1975.000000 
z"/>
<path fill="#FCFDF9" opacity="1.000000" stroke="none" 
	d="
M1569.620361,259.366730 
	C1680.211426,334.879883 1770.928711,428.883636 1840.219727,543.222656 
	C1895.680420,634.740051 1933.091919,733.150330 1952.109619,838.522278 
	C1963.820679,903.408875 1968.132812,968.788818 1964.701294,1034.594360 
	C1956.844971,1185.253174 1912.870605,1324.721924 1832.397339,1452.260376 
	C1735.771362,1605.398438 1604.092529,1719.202637 1440.872681,1796.841553 
	C1368.964844,1831.046021 1293.728271,1855.377441 1215.598511,1870.634399 
	C1140.899170,1885.221436 1065.497314,1891.061157 989.404724,1888.250488 
	C883.686829,1884.345581 781.164185,1864.377930 682.168945,1827.043579 
	C535.568359,1771.755737 409.679779,1686.086792 305.857422,1568.528564 
	C228.527283,1480.967651 170.498886,1382.010010 132.387680,1271.509277 
	C111.518196,1210.999878 97.735092,1148.892334 90.805565,1085.238647 
	C84.544022,1027.721191 83.914658,970.144775 89.269470,912.579224 
	C101.659210,779.386536 142.384827,655.330444 212.453171,541.419006 
	C320.938232,365.052612 473.063690,240.243988 664.534851,162.747589 
	C728.660706,136.793137 795.047424,118.655754 863.264954,107.349854 
	C929.707031,96.338211 996.595764,92.136253 1063.934692,94.779495 
	C1199.734985,100.110016 1328.917480,131.919189 1451.319580,191.043411 
	C1492.320557,210.848160 1531.600952,233.662643 1569.620361,259.366730 
M1872.036743,686.481018 
	C1785.888794,476.292419 1638.509033,323.446320 1435.902222,223.185333 
	C1322.194092,166.916321 1201.246460,137.227402 1074.564819,130.743790 
	C996.141357,126.730034 918.389282,132.153015 841.362610,147.342453 
	C759.742249,163.437729 681.476013,189.661911 607.179810,227.151169 
	C440.156494,311.429749 309.762909,434.158936 220.263336,598.993835 
	C161.103012,707.951660 128.834656,824.754456 122.204094,948.573425 
	C119.425446,1000.461792 121.775970,1052.150391 128.776505,1103.652954 
	C137.925964,1170.965454 154.912140,1236.226807 180.513596,1299.118652 
	C249.181015,1467.804932 360.981598,1601.270752 512.591431,1701.254517 
	C653.284668,1794.039062 808.582214,1843.306763 976.751099,1852.156250 
	C1071.399292,1857.136841 1164.842773,1848.559326 1256.695801,1825.030884 
	C1447.903809,1776.052490 1608.637207,1677.626953 1735.309204,1525.800537 
	C1843.588379,1396.019165 1907.383423,1246.655518 1925.769409,1078.359497 
	C1932.435303,1017.342285 1931.907471,956.255310 1924.773193,895.289185 
	C1916.390137,823.652710 1898.977173,754.250183 1872.036743,686.481018 
z"/>
<path fill="#47AC3F" opacity="1.000000" stroke="none" 
	d="
M1872.174316,686.841309 
	C1898.977173,754.250183 1916.390137,823.652710 1924.773193,895.289185 
	C1931.907471,956.255310 1932.435303,1017.342285 1925.769409,1078.359497 
	C1907.383423,1246.655518 1843.588379,1396.019165 1735.309204,1525.800537 
	C1608.637207,1677.626953 1447.903809,1776.052490 1256.695801,1825.030884 
	C1164.842773,1848.559326 1071.399292,1857.136841 976.751099,1852.156250 
	C808.582214,1843.306763 653.284668,1794.039062 512.591431,1701.254517 
	C360.981598,1601.270752 249.181015,1467.804932 180.513596,1299.118652 
	C154.912140,1236.226807 137.925964,1170.965454 128.776505,1103.652954 
	C121.775970,1052.150391 119.425446,1000.461792 122.204094,948.573425 
	C128.834656,824.754456 161.103012,707.951660 220.263336,598.993835 
	C309.762909,434.158936 440.156494,311.429749 607.179810,227.151169 
	C681.476013,189.661911 759.742249,163.437729 841.362610,147.342453 
	C918.389282,132.153015 996.141357,126.730034 1074.564819,130.743790 
	C1201.246460,137.227402 1322.194092,166.916321 1435.902222,223.185333 
	C1638.509033,323.446320 1785.888794,476.292419 1872.174316,686.841309 
M1025.617554,1322.326538 
	C1031.356201,1322.921143 1034.020386,1326.806763 1036.037109,1331.674194 
	C1042.335693,1346.875610 1048.740112,1362.033691 1055.221924,1377.157959 
	C1055.862915,1378.653564 1057.316040,1379.801025 1058.392090,1381.110107 
	C1059.409180,1379.741333 1060.750610,1378.509155 1061.392822,1376.982544 
	C1067.129272,1363.347168 1072.758545,1349.666626 1078.384155,1335.984863 
	C1080.751221,1330.228027 1082.505249,1324.046631 1089.145020,1321.449463 
	C1089.236938,1321.413452 1089.120850,1320.845947 1089.101929,1320.525513 
	C1082.817871,1320.525513 1076.575806,1320.525513 1070.333740,1320.525513 
	C1071.333008,1321.916016 1072.375977,1322.276733 1073.448364,1322.487793 
	C1076.337036,1323.056152 1077.587280,1324.788818 1076.527344,1327.524658 
	C1071.798340,1339.730347 1066.942017,1351.886597 1061.683105,1365.196289 
	C1058.890625,1358.681274 1056.586304,1353.387207 1054.349609,1348.064819 
	C1051.902588,1342.242188 1049.357300,1336.452759 1047.163696,1330.534302 
	C1045.742310,1326.699463 1045.899170,1323.180176 1051.118652,1322.262329 
	C1051.793213,1322.143677 1052.395508,1321.614380 1053.286255,1321.139160 
	C1052.621582,1320.541016 1052.289917,1319.980835 1051.952271,1319.977173 
	C1043.634399,1319.887085 1035.315552,1319.810181 1026.998779,1319.907593 
	C1026.379395,1319.914795 1025.775391,1321.247925 1024.441040,1322.250977 
	C1012.975159,1311.535278 1008.196777,1323.640259 1000.977844,1330.026978 
	C1000.977844,1325.409424 1000.977844,1321.825439 1000.977844,1317.163208 
	C993.687378,1320.105103 987.178101,1322.731812 979.590942,1325.793335 
	C981.424377,1326.766724 981.815796,1327.153198 982.212463,1327.158569 
	C989.574097,1327.260376 990.159607,1327.591919 990.325745,1334.902100 
	C990.563782,1345.375366 990.490356,1355.856934 990.461365,1366.334717 
	C990.437805,1374.843384 990.035706,1375.277100 981.916565,1377.477661 
	C981.622559,1377.557251 981.477417,1378.186035 981.048035,1378.932495 
	C991.455505,1378.932495 1001.490967,1378.932495 1012.955994,1378.932495 
	C1011.206421,1377.747681 1010.758057,1377.240356 1010.218262,1377.109131 
	C1002.906799,1375.330933 1001.776733,1374.469727 1001.478333,1367.298218 
	C1001.084595,1357.833496 1000.593079,1348.278564 1001.519836,1338.898438 
	C1001.905640,1334.994019 1005.443237,1331.055786 1008.420532,1327.977051 
	C1009.241699,1327.127808 1013.312073,1329.077026 1015.703064,1330.158936 
	C1021.093689,1332.598267 1024.969482,1330.139404 1024.828613,1324.184448 
	C1024.810181,1323.406738 1024.973755,1322.624634 1025.617554,1322.326538 
M1540.499878,1011.333130 
	C1639.784180,1011.333130 1739.068359,1011.333130 1838.595459,1011.333130 
	C1838.595459,995.911804 1838.595459,981.033325 1838.595459,966.200684 
	C1304.895752,966.200684 771.552551,966.200684 238.292465,966.200684 
	C238.292465,981.418091 238.292465,996.170349 238.292465,1011.333191 
	C672.264893,1011.333191 1105.882324,1011.333191 1540.499878,1011.333130 
M1169.898315,865.393250 
	C1173.316284,858.644592 1178.174805,852.224426 1179.806519,845.068481 
	C1182.115967,834.941162 1182.772827,824.277039 1182.862305,813.831421 
	C1183.224854,771.502747 1183.193726,729.169678 1183.097290,686.838684 
	C1183.068848,674.350220 1182.598877,661.849121 1181.890137,649.378906 
	C1180.586304,626.440125 1170.444458,608.910767 1148.351318,600.720276 
	C1138.369263,597.019653 1127.385132,594.706360 1116.758789,594.300598 
	C1081.815063,592.966492 1046.827148,592.791138 1011.855957,592.189026 
	C1011.067078,592.175415 1010.274414,592.380127 1009.353333,592.499939 
	C1009.353333,688.975220 1009.353333,785.188660 1009.353333,881.595642 
	C1010.488220,881.691406 1011.293945,881.818665 1012.099670,881.818909 
	C1044.098877,881.828613 1076.099121,881.969177 1108.096680,881.740051 
	C1117.734619,881.671021 1127.398315,880.883728 1136.990479,879.863525 
	C1149.152832,878.570068 1160.353760,874.626465 1169.898315,865.393250 
M1348.959229,643.516541 
	C1346.626221,627.871704 1344.207153,612.238892 1342.030396,596.572327 
	C1341.577759,593.314026 1340.497803,592.087402 1337.051514,592.099365 
	C1303.553467,592.215942 1270.054688,592.208557 1236.556641,592.099854 
	C1233.298706,592.089294 1232.343872,593.275085 1231.944946,596.335449 
	C1227.080078,633.654480 1222.080811,670.956055 1217.126831,708.263489 
	C1210.506836,758.116577 1203.887207,807.969604 1197.290039,857.825745 
	C1196.253906,865.655701 1195.338501,873.501587 1194.354370,881.444397 
	C1220.630127,881.444397 1246.180664,881.444397 1272.021973,881.444397 
	C1273.388428,865.490845 1274.813599,849.906311 1275.987549,834.302917 
	C1276.238037,830.974121 1277.193604,829.597900 1280.730225,829.745667 
	C1286.885742,830.002808 1293.068726,830.011902 1299.223022,829.738281 
	C1302.822388,829.578186 1303.704468,831.097412 1303.916138,834.340820 
	C1304.848755,848.636536 1306.121704,862.911011 1306.961670,877.211304 
	C1307.179932,880.924500 1308.397339,881.939819 1312.038086,881.914795 
	C1334.702759,881.758911 1357.368774,881.841919 1380.034424,881.817017 
	C1381.292480,881.815674 1382.550171,881.559326 1384.336792,881.363770 
	C1372.528687,802.107605 1360.780029,723.251953 1348.959229,643.516541 
M1529.927734,761.385681 
	C1524.611938,801.376526 1519.296143,841.367432 1513.918335,881.824524 
	C1539.438599,881.824524 1564.075317,881.793640 1588.711792,881.866272 
	C1591.576294,881.874695 1591.855469,880.329590 1592.025391,878.100281 
	C1592.683838,869.465149 1593.440674,860.837097 1594.216553,852.211426 
	C1594.880981,844.822998 1595.621216,837.441284 1596.320190,830.136597 
	C1605.578003,830.136597 1614.179077,830.136597 1623.094360,830.136597 
	C1624.492920,847.439026 1625.868286,864.455139 1627.248657,881.534424 
	C1653.082031,881.534424 1678.330322,881.534424 1704.040161,881.534424 
	C1689.680908,784.926880 1675.375610,688.682556 1661.076416,592.479492 
	C1624.485718,592.479492 1588.565430,592.479492 1552.296265,592.479492 
	C1544.843384,648.662354 1537.425659,704.581177 1529.927734,761.385681 
M450.202087,714.233215 
	C449.551422,718.428406 452.316101,718.199951 455.143250,718.194580 
	C476.970581,718.153137 498.798035,718.175781 520.625427,718.155334 
	C522.073547,718.153931 523.521484,717.961670 524.898804,717.863220 
	C525.132935,717.035095 525.395020,716.563660 525.378174,716.102417 
	C524.552979,693.486633 524.563049,670.798035 522.631836,648.276062 
	C520.402649,622.278503 505.354980,604.632751 481.639740,594.971375 
	C452.192657,582.975098 422.154846,582.684387 392.584198,594.566345 
	C372.366364,602.690308 358.097748,617.440552 352.118805,638.591736 
	C349.350494,648.384949 348.402618,658.941833 348.240875,669.177917 
	C347.674957,704.992126 347.441162,740.821533 347.841064,776.636719 
	C348.032135,793.745178 349.186584,810.924500 351.237244,827.910889 
	C354.132599,851.894104 366.882019,869.112000 389.459351,878.794128 
	C403.804321,884.945740 418.813721,887.117249 434.220764,887.575256 
	C452.137024,888.107788 469.459717,885.564636 485.410736,876.932495 
	C506.788116,865.364014 520.848511,848.442627 522.900696,823.353394 
	C523.945190,810.584229 524.632263,797.781860 525.243713,784.983154 
	C525.541443,778.750244 525.295166,772.491333 525.295166,766.263794 
	C499.944366,766.263794 475.249023,766.263794 450.198883,766.263794 
	C450.198883,768.523071 450.205200,770.333374 450.197906,772.143677 
	C450.140015,786.472107 450.244598,800.804199 449.933746,815.127136 
	C449.819000,820.415100 449.151031,825.780701 447.957794,830.930725 
	C446.722931,836.260254 442.445068,838.817444 436.621613,838.819763 
	C430.504700,838.822205 426.145325,836.155457 425.067139,830.613831 
	C423.897461,824.601929 423.100891,818.412842 423.082214,812.298157 
	C422.929535,762.312622 422.922058,712.326233 423.089172,662.340820 
	C423.110718,655.895752 423.936554,649.364929 425.211365,643.039307 
	C426.323242,637.522339 431.500824,634.851257 438.136627,635.245728 
	C444.385193,635.617188 447.962097,638.460876 448.830872,644.380310 
	C449.530273,649.145874 449.992432,653.987671 450.037537,658.800476 
	C450.207672,676.960327 450.164581,695.122131 450.202087,714.233215 
M907.654114,694.924438 
	C907.289551,696.916626 906.924988,698.908813 906.504028,701.209106 
	C900.374268,664.637573 894.316711,628.496582 888.274048,592.444763 
	C864.542786,592.444763 841.953674,592.444763 818.879578,592.444763 
	C819.388123,594.558350 819.742188,596.314148 820.233643,598.030640 
	C835.576111,651.619934 851.005188,705.184692 866.207336,758.813721 
	C868.502930,766.911865 871.083191,775.281067 871.245117,783.569458 
	C871.843872,814.220703 871.484070,844.890564 871.484436,875.553467 
	C871.484436,877.498718 871.484436,879.443970 871.484436,881.400513 
	C895.089966,881.400513 917.959839,881.400513 941.162842,881.400513 
	C941.162842,878.977783 941.162842,876.991333 941.162903,875.004883 
	C941.163879,844.175354 941.117310,813.345642 941.232483,782.516541 
	C941.245300,779.087585 941.772339,775.541931 942.744934,772.252686 
	C959.966675,714.010620 977.296570,655.800476 994.588989,597.579285 
	C995.075989,595.939758 995.407227,594.253967 995.854553,592.410217 
	C972.563232,592.410217 949.950806,592.410217 926.173401,592.410217 
	C920.046204,626.326782 913.924805,660.211365 907.654114,694.924438 
M764.825073,879.076843 
	C764.825073,802.976196 764.825073,726.875610 764.825073,650.105957 
	C779.948059,650.105957 794.491211,650.105957 809.154480,650.105957 
	C809.154480,630.512939 809.154480,611.591980 809.154480,592.590698 
	C754.306946,592.590698 699.787598,592.590698 645.342163,592.590698 
	C645.342163,611.943787 645.342163,630.864746 645.342163,650.326111 
	C660.095093,650.326111 674.515381,650.326111 689.631104,650.326111 
	C689.631104,727.742065 689.631104,804.616516 689.631104,881.810120 
	C714.321167,881.810120 738.301208,881.828308 762.280823,881.733521 
	C763.114258,881.730225 763.943298,880.599915 764.825073,879.076843 
M1408.509277,828.499573 
	C1408.509277,846.115051 1408.509277,863.730591 1408.509277,881.470581 
	C1433.888428,881.470581 1458.603516,881.470581 1483.994019,881.470581 
	C1483.994019,804.260315 1483.994019,727.413513 1483.994019,650.086792 
	C1499.092529,650.086792 1513.521484,650.086792 1528.079834,650.086792 
	C1528.079834,630.562744 1528.079834,611.526611 1528.079834,592.515503 
	C1473.229370,592.515503 1418.827393,592.515503 1364.282104,592.515503 
	C1364.282104,611.783875 1364.282104,630.698730 1364.282104,649.927429 
	C1366.155640,650.018127 1367.775879,650.161133 1369.396240,650.164429 
	C1379.726440,650.185425 1390.056763,650.130554 1400.386597,650.189697 
	C1409.381470,650.241211 1408.492310,649.006348 1408.496216,658.052063 
	C1408.520142,714.534546 1408.508911,771.017029 1408.509277,828.499573 
M563.549561,881.836365 
	C584.810608,881.836365 606.071655,881.836365 627.390015,881.836365 
	C627.390015,784.908142 627.390015,688.721497 627.390015,592.426819 
	C602.358826,592.426819 577.762329,592.426819 552.857056,592.426819 
	C552.857056,688.908691 552.857056,785.127014 552.857056,881.836121 
	C556.309265,881.836121 559.440430,881.836121 563.549561,881.836365 
M1016.746826,1130.190918 
	C1017.184570,1133.977051 1017.948120,1137.758911 1017.999573,1141.550293 
	C1018.162415,1153.541382 1018.072693,1165.536011 1018.054382,1177.529297 
	C1018.037109,1188.881592 1017.010193,1190.006104 1005.856873,1191.159912 
	C1005.590393,1191.187378 1005.359863,1191.561646 1004.911255,1191.947266 
	C1005.191223,1192.562256 1005.496155,1193.833496 1005.810425,1193.835815 
	C1019.130798,1193.934937 1032.452026,1193.921021 1045.773071,1193.921021 
	C1045.881348,1193.360596 1045.989502,1192.800171 1046.097778,1192.239746 
	C1045.009644,1191.815674 1043.953857,1191.164917 1042.828125,1191.004028 
	C1034.850220,1189.863647 1033.006958,1187.966797 1032.971558,1180.108398 
	C1032.906128,1165.616943 1032.851929,1151.123413 1033.056274,1136.634521 
	C1033.085571,1134.550293 1034.055542,1131.951050 1035.508423,1130.527588 
	C1038.321289,1127.772217 1041.512573,1125.108887 1045.033447,1123.423950 
	C1057.307617,1117.550903 1066.554077,1123.041870 1066.915283,1136.600464 
	C1067.305542,1151.246948 1067.156616,1165.913696 1066.959229,1180.567627 
	C1066.858521,1188.043701 1064.429565,1190.242798 1057.133545,1191.036987 
	C1055.981201,1191.162476 1054.907227,1192.007202 1053.796997,1192.519287 
	C1053.948364,1193.065674 1054.099854,1193.611938 1054.251221,1194.158203 
	C1066.862671,1194.158203 1079.474243,1194.183105 1092.084717,1194.084351 
	C1092.712402,1194.079468 1093.331787,1193.001343 1093.955078,1192.423096 
	C1093.070435,1191.937744 1092.234985,1191.180664 1091.293457,1191.010254 
	C1084.156372,1189.719116 1082.158447,1187.633179 1082.052246,1180.437256 
	C1081.907227,1170.611572 1082.095581,1160.780273 1081.896729,1150.956177 
	C1081.748779,1143.644287 1081.742554,1136.265869 1080.728027,1129.050049 
	C1079.144165,1117.785278 1072.006470,1111.387451 1062.161499,1110.791626 
	C1052.947754,1110.233765 1045.702271,1114.063477 1039.390625,1120.228760 
	C1037.092773,1122.473145 1035.023315,1124.951416 1032.624146,1127.569092 
	C1026.359985,1113.798096 1019.890137,1108.970215 1008.329651,1111.268066 
	C1002.634521,1112.400269 997.372314,1116.302368 992.248169,1119.488037 
	C989.296753,1121.322754 987.011963,1124.229858 983.362793,1127.652466 
	C983.362793,1121.060547 983.362793,1115.988647 983.362793,1110.187012 
	C980.760742,1110.795776 978.812439,1111.024536 977.039185,1111.707520 
	C970.987000,1114.038696 964.959045,1116.443848 959.012878,1119.029785 
	C957.773315,1119.568848 956.922791,1121.002319 955.893005,1122.023560 
	C957.189392,1122.300293 958.514221,1122.906982 959.777405,1122.798340 
	C966.183289,1122.246582 968.027527,1123.081543 968.206177,1129.430420 
	C968.697693,1146.897705 968.830261,1164.385254 968.590088,1181.857788 
	C968.499817,1188.424683 966.242615,1189.967407 959.633850,1191.021606 
	C958.584595,1191.188965 957.651917,1192.087280 956.665344,1192.647827 
	C957.708984,1193.162109 958.748535,1194.113525 959.796997,1194.123535 
	C970.956360,1194.230103 982.117615,1194.231201 993.276978,1194.121704 
	C994.477112,1194.109985 995.668518,1193.216187 996.864014,1192.731445 
	C995.773315,1191.360352 995.016968,1191.083252 994.240295,1191.003052 
	C985.565186,1190.107056 983.966125,1188.882324 983.771851,1180.353271 
	C983.426697,1165.203857 983.495605,1150.042236 983.626465,1134.887939 
	C983.641418,1133.160522 984.709656,1130.797241 986.070374,1129.846680 
	C990.254456,1126.924194 994.573547,1123.909058 999.288879,1122.097778 
	C1007.004395,1119.134155 1012.773376,1121.978394 1016.746826,1130.190918 
M849.187256,1190.645020 
	C841.938721,1189.164062 842.357788,1183.241699 842.279663,1178.030640 
	C842.067444,1163.873413 842.126831,1149.710083 842.283325,1135.551392 
	C842.303101,1133.768677 843.030945,1131.216797 844.319763,1130.362793 
	C849.249695,1127.095825 854.263428,1123.578003 859.769958,1121.652954 
	C866.878723,1119.167725 873.075989,1122.992676 875.363281,1130.206177 
	C876.255798,1133.020874 876.801941,1136.064697 876.835022,1139.011719 
	C876.988098,1152.670654 876.990479,1166.333008 876.875610,1179.992676 
	C876.806580,1188.205688 874.885071,1190.063965 866.686768,1191.043091 
	C865.673218,1191.164185 864.758301,1192.111206 863.797729,1192.675415 
	C864.837036,1193.179810 865.872437,1194.113525 866.916138,1194.122803 
	C878.410095,1194.225830 889.905823,1194.226685 901.399841,1194.121704 
	C902.606873,1194.110718 903.806152,1193.244629 905.009033,1192.775269 
	C903.945007,1191.386230 903.200317,1191.089478 902.432312,1191.004517 
	C893.434021,1190.008911 891.887207,1188.501709 891.779480,1179.450562 
	C891.630798,1166.957764 892.436401,1154.406982 891.479919,1141.985229 
	C890.714294,1132.042847 896.024719,1127.207153 903.486450,1123.507446 
	C915.593384,1117.504639 924.873352,1122.777832 925.781128,1136.256714 
	C926.306274,1144.053101 926.024170,1151.909424 925.970459,1159.737915 
	C925.917908,1167.393066 926.052551,1175.076416 925.430542,1182.692261 
	C924.944885,1188.637329 922.497803,1190.365356 916.741638,1191.034668 
	C915.436218,1191.186523 914.230347,1192.194458 912.978638,1192.808350 
	C914.233398,1193.269653 915.484497,1194.119385 916.743469,1194.131104 
	C927.737671,1194.233887 938.733276,1194.203979 949.728271,1194.156372 
	C950.766174,1194.151978 951.802368,1193.758179 952.839417,1193.545044 
	C952.940674,1193.135620 953.041870,1192.726074 953.143127,1192.316650 
	C952.220215,1191.872070 951.339233,1191.193970 950.367859,1191.019653 
	C942.614197,1189.629028 941.069336,1188.020142 940.954468,1180.255615 
	C940.821289,1171.261597 941.040771,1162.260376 940.805237,1153.270264 
	C940.591919,1145.130859 940.753357,1136.865479 939.359985,1128.898560 
	C936.895142,1114.805298 928.298767,1108.760620 914.310425,1111.343140 
	C909.362549,1112.256470 904.610229,1115.485718 900.355225,1118.488770 
	C897.078674,1120.801147 894.719910,1124.413940 891.445679,1128.005005 
	C890.588745,1125.928101 890.218811,1125.041138 889.856201,1124.151123 
	C886.036255,1114.774658 879.391968,1110.491577 869.262146,1110.827637 
	C862.017090,1111.067993 856.256897,1114.490967 851.123169,1119.241699 
	C848.430298,1121.733765 845.809265,1124.303467 843.155701,1126.838135 
	C842.776978,1126.604126 842.398193,1126.370239 842.019409,1126.136230 
	C842.019409,1121.062744 842.019409,1115.989136 842.019409,1109.422485 
	C832.119324,1113.317017 823.173340,1116.836182 814.227356,1120.355225 
	C814.261230,1121.022217 814.295105,1121.689087 814.328918,1122.355957 
	C815.868408,1122.492554 817.430786,1122.868408 818.943237,1122.723389 
	C824.686157,1122.172485 826.798584,1123.295044 826.921204,1128.810059 
	C827.313293,1146.450073 827.515015,1164.102173 827.300659,1181.743652 
	C827.219177,1188.448608 824.956970,1190.102051 818.520447,1191.007812 
	C817.401733,1191.165161 816.365173,1191.905884 815.290649,1192.377075 
	C816.402405,1192.980103 817.508118,1194.093872 818.626770,1194.107178 
	C829.786987,1194.239746 840.949707,1194.230713 852.110596,1194.129517 
	C853.185364,1194.119873 854.252197,1193.219238 855.322693,1192.732178 
	C854.205139,1192.144165 853.118530,1191.481689 851.956970,1190.999512 
	C851.368347,1190.755127 850.644592,1190.836182 849.187256,1190.645020 
M395.181061,1109.500000 
	C395.181061,1100.382568 395.181061,1091.265137 395.181061,1081.828003 
	C402.404785,1081.828003 409.035278,1081.496338 415.619812,1081.905151 
	C425.679138,1082.529663 430.488434,1086.829468 433.163910,1096.664429 
	C433.640808,1098.417358 434.146729,1100.162354 434.639221,1101.911011 
	C435.016724,1101.816162 435.394196,1101.721191 435.771698,1101.626343 
	C435.771698,1092.519409 435.771698,1083.412354 435.771698,1074.384277 
	C402.607971,1074.384277 369.880188,1074.384277 336.816223,1074.384277 
	C336.816223,1082.398560 336.749969,1090.046143 336.874573,1097.690674 
	C336.898254,1099.141724 337.678223,1100.580322 338.109161,1102.024658 
	C338.973297,1100.692139 340.361389,1099.451050 340.612518,1098.011597 
	C341.809601,1091.149902 344.604492,1084.876465 351.679291,1083.411743 
	C360.131378,1081.661743 368.942963,1081.648071 377.874481,1080.857910 
	C378.024689,1083.416016 378.149780,1084.556763 378.150116,1085.697632 
	C378.158417,1115.685547 378.162018,1145.673462 378.132843,1175.661377 
	C378.130890,1177.653564 378.042938,1179.678467 377.685608,1181.631592 
	C376.507751,1188.069336 373.811615,1190.376831 367.245361,1190.877563 
	C365.235229,1191.030762 363.230865,1191.258911 361.223846,1191.452759 
	C361.156067,1191.976807 361.088257,1192.500854 361.020477,1193.024902 
	C362.157654,1193.411621 363.293121,1194.129028 364.432281,1194.134888 
	C379.259216,1194.212036 394.086914,1194.222534 408.913544,1194.121826 
	C410.052643,1194.114136 411.185211,1193.143555 412.320801,1192.620605 
	C412.180695,1192.167847 412.040588,1191.715210 411.900482,1191.262573 
	C410.454742,1191.163452 409.010223,1191.040039 407.563080,1190.969238 
	C399.219360,1190.559937 396.753021,1188.357422 395.772278,1179.941895 
	C395.349365,1176.312988 395.216278,1172.633545 395.206329,1168.976318 
	C395.153412,1149.484375 395.181213,1129.992188 395.181061,1109.500000 
M1231.928589,1113.228638 
	C1226.392700,1117.471802 1220.856812,1121.715088 1214.927002,1126.260254 
	C1214.927002,1120.954102 1214.927002,1116.001465 1214.927002,1109.316772 
	C1205.018433,1113.262207 1195.999756,1116.853271 1186.981079,1120.444214 
	C1187.001953,1120.970947 1187.022949,1121.497681 1187.043823,1122.024414 
	C1188.212402,1122.347534 1189.403809,1123.014771 1190.545776,1122.938599 
	C1198.587524,1122.401611 1199.814331,1123.122437 1199.948730,1131.014648 
	C1200.226440,1147.333862 1200.310791,1163.661377 1200.117065,1179.981323 
	C1200.022095,1187.980469 1198.276489,1189.490601 1190.368774,1191.032349 
	C1189.583740,1191.185425 1188.918213,1191.950317 1188.197144,1192.431274 
	C1188.992798,1193.012085 1189.783691,1194.091064 1190.584839,1194.098877 
	C1202.244873,1194.211060 1213.906616,1194.213013 1225.567017,1194.117554 
	C1226.447876,1194.110474 1227.321045,1193.166382 1228.197876,1192.657837 
	C1227.389404,1192.101196 1226.635498,1191.173096 1225.764038,1191.045166 
	C1216.516479,1189.686646 1215.182983,1188.367065 1215.114746,1179.067017 
	C1215.010864,1164.907715 1215.028809,1150.746582 1215.151001,1136.587646 
	C1215.165161,1134.932617 1215.670044,1132.698608 1216.800781,1131.743652 
	C1220.448120,1128.664062 1224.166138,1125.320679 1228.450928,1123.411865 
	C1239.386108,1118.539917 1247.271118,1122.933105 1248.021240,1134.831543 
	C1248.931274,1149.269165 1248.772705,1163.785156 1248.749390,1178.266724 
	C1248.732910,1188.549316 1246.937500,1190.191040 1236.649414,1191.174072 
	C1236.386719,1191.199219 1236.156128,1191.558838 1234.999878,1192.517456 
	C1236.682617,1193.214722 1237.853638,1194.112915 1239.033325,1194.124512 
	C1250.027100,1194.233643 1261.023071,1194.237671 1272.016846,1194.125610 
	C1273.250610,1194.113037 1274.475098,1193.192139 1275.703979,1192.692505 
	C1274.559692,1192.116821 1273.437134,1191.099487 1272.267212,1191.041748 
	C1267.068115,1190.784546 1264.297119,1188.180420 1263.797241,1183.058594 
	C1263.619751,1181.239502 1263.345947,1179.418823 1263.328247,1177.596802 
	C1263.190430,1163.439209 1263.411011,1149.270752 1262.928223,1135.125854 
	C1262.250977,1115.288818 1249.635254,1106.237305 1231.928589,1113.228638 
M1761.093506,1189.568481 
	C1756.927612,1185.879761 1757.493408,1180.777954 1757.394043,1176.063843 
	C1757.165894,1165.237427 1757.467041,1154.400146 1757.254761,1143.573120 
	C1757.147583,1138.102417 1756.964111,1132.521118 1755.818604,1127.204468 
	C1752.692505,1112.694580 1739.439209,1106.361084 1726.303711,1113.271851 
	C1721.395752,1115.853882 1717.271362,1119.974365 1712.948853,1123.583862 
	C1711.908569,1124.452393 1711.500732,1126.078491 1710.802002,1127.356079 
	C1710.215698,1126.951172 1709.629272,1126.546387 1709.042969,1126.141479 
	C1709.042969,1121.082031 1709.042969,1116.022583 1709.042969,1109.407349 
	C1699.108154,1113.300903 1690.155151,1116.809814 1681.202026,1120.318604 
	C1681.212646,1120.968018 1681.223389,1121.617432 1681.234009,1122.266846 
	C1682.754395,1122.439575 1684.300781,1122.881592 1685.790649,1122.737793 
	C1691.769043,1122.160767 1693.759644,1123.278687 1693.858887,1129.206665 
	C1694.154175,1146.854736 1694.203369,1164.511841 1693.984497,1182.160767 
	C1693.910645,1188.121338 1691.269775,1190.190308 1685.271240,1191.026855 
	C1684.100342,1191.190186 1683.021484,1192.014526 1681.900146,1192.533936 
	C1682.050293,1193.075928 1682.200317,1193.617798 1682.350464,1194.159790 
	C1694.968384,1194.159790 1707.586548,1194.185059 1720.203613,1194.087158 
	C1720.851685,1194.082153 1721.491577,1193.020630 1722.135132,1192.450928 
	C1721.416260,1191.968140 1720.680420,1191.042725 1719.981079,1191.069336 
	C1709.982422,1191.451294 1709.340576,1186.620972 1709.229492,1178.688354 
	C1709.030884,1164.527344 1709.095703,1150.362427 1709.126587,1136.199219 
	C1709.129395,1134.953125 1709.314819,1133.380127 1710.061035,1132.517334 
	C1714.385864,1127.516968 1719.499023,1123.528687 1726.141846,1122.165649 
	C1735.561279,1120.232788 1741.454102,1123.957397 1741.940186,1133.687134 
	C1742.745117,1149.800415 1742.690552,1165.973633 1742.450073,1182.113525 
	C1742.351929,1188.703735 1739.959106,1190.296143 1733.384644,1191.035522 
	C1732.197144,1191.169067 1731.101318,1192.119629 1729.963135,1192.692871 
	C1731.192993,1193.192871 1732.418701,1194.114502 1733.653442,1194.126953 
	C1744.650146,1194.238403 1755.648560,1194.208130 1766.646118,1194.156616 
	C1767.676758,1194.151733 1768.705200,1193.715088 1769.734741,1193.478760 
	C1769.812012,1192.990723 1769.889282,1192.502686 1769.966553,1192.014648 
	C1767.244019,1191.328125 1764.521484,1190.641479 1761.093506,1189.568481 
M1131.154175,1184.788330 
	C1125.573608,1182.102051 1123.987915,1177.037354 1123.902100,1171.528198 
	C1123.629883,1154.050903 1123.623291,1136.569336 1123.517212,1119.089478 
	C1123.505493,1117.161011 1123.515625,1115.232178 1123.515625,1113.392334 
	C1113.556152,1113.392334 1104.364258,1113.392334 1095.172241,1113.392334 
	C1095.030273,1113.817871 1094.888184,1114.243408 1094.746216,1114.668945 
	C1095.921997,1115.255005 1097.042725,1116.162720 1098.282593,1116.375610 
	C1107.216309,1117.909546 1108.777222,1119.645386 1108.807983,1128.792236 
	C1108.845581,1139.948486 1108.548462,1151.113770 1108.916748,1162.257935 
	C1109.140503,1169.027466 1109.660278,1175.933472 1111.270630,1182.477173 
	C1113.841553,1192.923462 1121.468140,1197.469238 1132.255615,1196.649292 
	C1138.102173,1196.204834 1142.694580,1193.549316 1146.829102,1189.760254 
	C1150.048340,1186.810059 1153.180908,1183.765625 1157.234375,1179.927612 
	C1157.234375,1186.311890 1157.234375,1191.411377 1157.234375,1196.540161 
	C1158.353394,1196.668335 1159.102417,1196.990723 1159.645752,1196.780884 
	C1167.690796,1193.673462 1175.722412,1190.529419 1183.702393,1187.260132 
	C1184.248413,1187.036499 1184.359497,1185.751343 1184.672852,1184.959839 
	C1183.633301,1184.806885 1182.556274,1184.396729 1181.560303,1184.542236 
	C1174.406372,1185.587524 1173.420410,1184.934204 1172.462036,1177.865601 
	C1172.172119,1175.727295 1172.039429,1173.553223 1172.026001,1171.394287 
	C1171.935913,1156.908936 1171.892822,1142.423218 1171.844482,1127.937622 
	C1171.828613,1123.149536 1171.842041,1118.361572 1171.842041,1113.355713 
	C1162.309570,1113.355713 1153.310181,1113.355713 1144.310669,1113.355713 
	C1144.150879,1113.756226 1143.991211,1114.156860 1143.831421,1114.557373 
	C1144.988403,1115.214600 1146.074463,1116.221436 1147.313599,1116.472534 
	C1155.614746,1118.154541 1156.987305,1119.612061 1157.027710,1128.030762 
	C1157.096558,1142.350464 1157.076782,1156.670898 1157.009521,1170.990601 
	C1157.002808,1172.396973 1156.874634,1174.271484 1156.009888,1175.130249 
	C1149.378540,1181.715576 1142.314819,1187.520874 1131.154175,1184.788330 
M1419.034180,1123.764893 
	C1418.436890,1126.657471 1417.276123,1129.568848 1417.372803,1132.438110 
	C1417.521729,1136.862915 1420.834106,1139.541992 1424.916992,1139.451538 
	C1429.116211,1139.358643 1431.675781,1136.736206 1432.015991,1132.042480 
	C1432.135742,1130.387451 1432.095459,1128.712158 1432.008301,1127.052368 
	C1431.741455,1121.971436 1434.475220,1118.857788 1438.848511,1117.135376 
	C1447.959473,1113.546875 1456.173828,1117.882690 1458.264282,1127.463745 
	C1458.965454,1130.677612 1459.219482,1134.091675 1458.978027,1137.362549 
	C1458.867798,1138.856445 1457.368286,1140.898193 1455.971313,1141.536133 
	C1449.324341,1144.572021 1442.301758,1146.825073 1435.791504,1150.108032 
	C1430.215088,1152.920166 1424.532104,1156.081055 1420.000854,1160.279785 
	C1412.426147,1167.298584 1412.110474,1180.545776 1418.334961,1188.705322 
	C1424.229370,1196.432129 1435.493408,1198.268311 1445.002319,1192.942993 
	C1447.170044,1191.728882 1449.158447,1190.184570 1451.183350,1188.728516 
	C1453.641357,1186.960938 1456.050415,1185.125610 1458.691162,1183.163208 
	C1461.169922,1192.560303 1462.201416,1194.158936 1466.239258,1195.338379 
	C1474.010986,1197.608643 1487.427246,1187.807861 1487.713013,1179.628052 
	C1487.720703,1179.408936 1487.376953,1179.177490 1486.994019,1178.696899 
	C1485.880981,1179.690674 1484.938354,1180.941040 1483.684204,1181.562988 
	C1481.438965,1182.676270 1479.006104,1183.410889 1476.651733,1184.303955 
	C1475.810303,1182.072754 1474.394043,1179.880493 1474.239868,1177.602783 
	C1473.846802,1171.800049 1474.072021,1165.958008 1473.951294,1160.133789 
	C1473.720337,1148.994385 1473.715820,1137.838623 1473.039795,1126.724731 
	C1472.628174,1119.958740 1468.283447,1115.437256 1462.029785,1113.184570 
	C1453.866089,1110.243774 1445.431641,1110.036011 1436.957886,1111.795898 
	C1429.665894,1113.310181 1423.629395,1116.817261 1419.034180,1123.764893 
M766.486023,1196.852783 
	C771.917908,1195.479370 777.838501,1195.058716 782.696655,1192.566772 
	C803.099670,1182.101562 811.843628,1156.863037 803.012085,1135.624390 
	C793.142334,1111.888916 766.784973,1103.452881 746.231873,1117.533936 
	C739.309875,1122.276245 734.707214,1128.960083 731.390930,1136.557129 
	C720.468994,1161.576538 735.083923,1195.628418 766.486023,1196.852783 
M1654.004272,1190.540161 
	C1673.806396,1177.010376 1679.672852,1149.226196 1667.001587,1128.984131 
	C1655.107300,1109.983276 1631.175903,1104.842163 1613.194214,1117.879028 
	C1601.732300,1126.189209 1595.922974,1138.073730 1595.315796,1152.084839 
	C1594.632202,1167.861816 1600.039185,1181.304810 1613.439575,1190.421753 
	C1626.462402,1199.281738 1639.965210,1198.792358 1654.004272,1190.540161 
M623.535828,1188.053345 
	C629.557007,1182.436768 633.405945,1175.564697 635.091919,1167.538086 
	C635.370117,1166.213501 634.767578,1164.704102 634.572083,1163.280029 
	C634.105713,1163.298096 633.639404,1163.316162 633.173035,1163.334229 
	C632.131958,1165.392090 631.123962,1167.467651 630.044128,1169.505005 
	C625.273071,1178.506592 617.883240,1182.756836 607.618286,1182.097168 
	C591.498413,1181.061279 577.918091,1162.100708 580.653931,1143.393311 
	C598.864929,1143.393311 617.199524,1143.393311 635.842346,1143.393311 
	C635.202637,1129.734619 630.398499,1119.438110 618.298523,1113.561646 
	C607.235779,1108.188965 590.926025,1110.578125 581.721863,1118.753906 
	C564.831543,1133.756958 562.183716,1164.286865 576.138550,1183.132446 
	C587.682312,1198.721924 608.292664,1201.103516 623.535828,1188.053345 
M466.537628,1179.214600 
	C454.671295,1171.921631 447.474854,1157.076172 449.629700,1143.380615 
	C467.848969,1143.380615 486.192596,1143.380615 504.509460,1143.380615 
	C504.698181,1129.342285 498.253967,1117.966064 486.797302,1113.429688 
	C473.969208,1108.350464 461.409729,1109.652344 450.756805,1118.843018 
	C434.667877,1132.723511 431.351044,1162.732666 443.405579,1180.742188 
	C451.667694,1193.085815 464.678680,1198.798706 478.143677,1195.995361 
	C490.205231,1193.484009 502.041260,1180.473755 504.161041,1167.205322 
	C504.354095,1165.996948 503.625519,1164.641357 503.022278,1162.057861 
	C491.959656,1181.944092 483.473267,1185.822388 466.537628,1179.214600 
M707.004639,1183.451904 
	C714.183044,1172.775757 716.467896,1165.377808 713.322998,1162.628296 
	C711.254028,1166.045166 709.399414,1169.592529 707.098816,1172.822266 
	C697.876038,1185.769287 680.732727,1186.651855 670.917908,1174.128906 
	C660.164917,1160.408813 659.018738,1144.876587 665.329285,1128.922607 
	C666.655334,1125.570312 669.521545,1122.414795 672.451416,1120.184448 
	C682.451965,1112.571655 692.898804,1116.667480 695.379089,1128.822266 
	C695.611877,1129.962891 695.798767,1131.127441 696.179810,1132.220703 
	C697.611450,1136.327393 702.401062,1138.532593 707.302124,1137.406860 
	C711.834656,1136.365723 713.782959,1133.498169 713.130005,1128.533691 
	C712.397949,1122.967041 708.768005,1119.302124 704.475281,1116.278442 
	C692.474976,1107.825928 674.449097,1109.098022 662.702637,1119.102661 
	C647.337952,1132.189087 642.947449,1155.341064 651.731750,1175.306274 
	C661.927246,1198.479248 689.612183,1204.876831 707.004639,1183.451904 
M1375.863525,1118.282349 
	C1377.549927,1121.010010 1379.404297,1123.652466 1380.851929,1126.501465 
	C1381.576172,1127.927124 1381.421021,1129.771729 1381.901489,1131.354492 
	C1383.482422,1136.561768 1388.854126,1139.020508 1394.342529,1137.142822 
	C1398.780762,1135.624268 1400.948364,1130.917480 1398.326782,1126.040649 
	C1396.281372,1122.235718 1393.252563,1118.476440 1389.732544,1116.035034 
	C1377.603149,1107.621704 1360.121704,1109.253662 1348.441895,1119.259155 
	C1331.250977,1133.985840 1327.894531,1162.432007 1341.195435,1181.638062 
	C1348.964722,1192.856445 1359.526489,1198.589355 1373.313232,1196.047363 
	C1390.097778,1192.952637 1399.039062,1176.852417 1401.130127,1164.775513 
	C1401.247192,1164.099243 1400.443359,1163.263550 1399.823608,1162.005737 
	C1396.891357,1166.541138 1394.642090,1170.792114 1391.675415,1174.463379 
	C1382.999512,1185.199585 1367.960449,1185.747925 1358.284180,1175.945190 
	C1346.846191,1164.357788 1344.017456,1140.420776 1352.440918,1126.499268 
	C1357.528809,1118.089966 1365.697144,1115.005859 1375.863525,1118.282349 
M517.093567,1191.829102 
	C518.330322,1192.617188 519.547180,1194.047974 520.807068,1194.087036 
	C527.958008,1194.308350 535.119263,1194.197388 542.276978,1194.198486 
	C547.050720,1194.199341 551.824463,1194.198608 556.598206,1194.198608 
	C556.758057,1193.709351 556.917969,1193.220093 557.077881,1192.730835 
	C556.246887,1192.153198 555.479309,1191.211426 554.575195,1191.054077 
	C544.898193,1189.369751 543.895813,1188.458618 543.860901,1178.645020 
	C543.737183,1143.856445 543.742065,1109.067383 543.698730,1074.278564 
	C543.696289,1072.321899 543.698425,1070.365112 543.698425,1068.365601 
	C542.500061,1068.197266 541.755920,1067.850464 541.221558,1068.054077 
	C533.167358,1071.123779 525.126648,1074.231079 517.140564,1077.472046 
	C516.629883,1077.679321 516.592590,1079.053101 516.335144,1079.884155 
	C517.190491,1080.111572 518.077515,1080.616455 518.896362,1080.523193 
	C527.127808,1079.585815 527.325256,1079.685181 528.368713,1087.614258 
	C528.650391,1089.754761 528.874573,1091.921997 528.877319,1094.077271 
	C528.913269,1122.375244 528.952759,1150.673462 528.880249,1178.971191 
	C528.856201,1188.355225 526.895325,1190.325195 517.093567,1191.829102 
M710.552673,1380.401367 
	C734.689026,1373.311157 740.067871,1343.919800 728.449524,1327.412476 
	C720.711060,1316.417603 707.576599,1315.020508 697.902588,1324.307495 
	C696.454468,1325.697632 695.483948,1327.585205 694.292480,1329.242798 
	C693.758667,1328.837158 693.224792,1328.431396 692.690918,1328.025757 
	C692.690918,1314.430054 692.690918,1300.834473 692.690918,1285.748779 
	C685.228882,1288.751343 678.665649,1291.392212 672.102478,1294.032959 
	C681.798645,1296.863159 681.801147,1296.863159 681.896118,1307.098267 
	C681.913086,1308.929688 681.900574,1310.761475 681.900574,1312.593018 
	C681.900452,1331.408447 682.010315,1350.225220 681.805420,1369.038452 
	C681.765076,1372.739990 683.023743,1374.706665 686.175659,1376.413696 
	C693.619995,1380.445190 701.369080,1382.127197 710.552673,1380.401367 
M1536.658813,1183.183350 
	C1537.312744,1181.677734 1537.966675,1180.172119 1538.620728,1178.666382 
	C1538.308594,1178.326416 1537.996338,1177.986450 1537.684204,1177.646362 
	C1536.488037,1178.548828 1535.020264,1179.244385 1534.145752,1180.391968 
	C1531.348999,1184.061890 1527.790405,1185.988159 1523.297607,1184.767212 
	C1518.878052,1183.566040 1518.047852,1179.625244 1517.661743,1175.668945 
	C1517.500000,1174.012207 1517.346436,1172.345825 1517.344116,1170.683716 
	C1517.320801,1154.687012 1517.318848,1138.690186 1517.356812,1122.693604 
	C1517.359375,1121.601196 1517.731812,1120.509644 1518.021118,1118.933594 
	C1524.174316,1118.933594 1530.101562,1118.933594 1536.052979,1118.933594 
	C1536.052979,1116.775146 1536.052979,1115.163818 1536.052979,1112.877686 
	C1529.785156,1112.877686 1523.741943,1112.877686 1516.903320,1112.877686 
	C1516.903320,1103.580688 1516.903320,1094.939331 1516.903320,1086.297974 
	C1516.464844,1086.212891 1516.026489,1086.127808 1515.588013,1086.042603 
	C1512.065430,1092.092529 1509.166504,1098.640747 1504.843018,1104.050537 
	C1500.608643,1109.348755 1494.923218,1113.487427 1488.781128,1119.141724 
	C1494.278320,1119.141724 1498.330688,1119.141724 1502.913452,1119.141724 
	C1502.913452,1121.560791 1502.913330,1123.346313 1502.913452,1125.131836 
	C1502.914917,1140.962036 1502.850586,1156.792603 1502.965820,1172.621948 
	C1502.993530,1176.426514 1503.212158,1180.318604 1504.049561,1184.010254 
	C1505.932251,1192.309937 1512.364990,1196.516724 1520.811646,1195.253418 
	C1527.947510,1194.186157 1532.799438,1189.873169 1536.658813,1183.183350 
M1717.655151,1372.320312 
	C1713.004028,1373.758911 1711.067749,1371.643433 1710.996948,1367.182007 
	C1710.835815,1357.032837 1710.676758,1346.883667 1710.568481,1336.733887 
	C1710.510132,1331.276245 1710.558105,1325.817383 1710.558105,1320.422485 
	C1703.407959,1320.422485 1696.858521,1320.422485 1690.309082,1320.422485 
	C1690.700928,1321.600830 1691.187866,1322.017090 1691.747803,1322.176025 
	C1699.008301,1324.237427 1699.599854,1324.960693 1699.623169,1332.407104 
	C1699.649780,1340.896118 1699.062866,1349.435059 1699.799683,1357.859741 
	C1700.452881,1365.327148 1696.426392,1368.895874 1690.831055,1371.668945 
	C1682.525146,1375.785278 1676.047119,1372.249023 1675.249878,1363.073975 
	C1674.804565,1357.949219 1675.040161,1352.762695 1675.025757,1347.603760 
	C1675.000488,1338.500488 1675.018677,1329.397217 1675.018677,1320.447388 
	C1667.693481,1320.447388 1660.982788,1320.447388 1654.272095,1320.447388 
	C1654.734985,1321.621948 1655.247925,1322.015991 1655.822632,1322.155762 
	C1663.656860,1324.061401 1664.066284,1324.505249 1664.116943,1332.472290 
	C1664.170898,1340.961060 1663.806396,1349.468140 1664.259277,1357.933228 
	C1664.539307,1363.166626 1665.525635,1368.484009 1667.069336,1373.494141 
	C1668.630981,1378.562744 1672.832886,1380.822998 1678.188843,1381.228516 
	C1683.730957,1381.648315 1688.297974,1379.870728 1692.267090,1376.158936 
	C1694.388672,1374.174561 1696.537231,1372.218994 1699.665894,1369.336060 
	C1699.665894,1374.028198 1699.665894,1377.403198 1699.665894,1382.351074 
	C1706.937622,1379.403198 1713.128906,1376.893311 1719.320068,1374.383423 
	C1719.013184,1373.642212 1718.706177,1372.901001 1717.655151,1372.320312 
M661.855225,1339.574707 
	C661.816711,1337.744141 661.872070,1335.905884 661.724121,1334.084106 
	C660.915588,1324.132080 656.931152,1319.865356 646.945557,1318.502075 
	C644.162964,1318.122192 641.288940,1318.004150 638.491272,1318.217529 
	C629.564270,1318.898438 621.978210,1323.770264 620.632507,1330.264526 
	C620.138855,1332.646606 621.115295,1335.736694 622.414246,1337.910400 
	C623.028870,1338.938843 626.832703,1339.464722 627.692871,1338.645142 
	C629.370972,1337.046387 630.767151,1334.390991 630.916687,1332.084717 
	C631.459290,1323.716675 637.991455,1319.446289 645.370483,1323.353516 
	C650.759827,1326.207275 650.593201,1331.720825 651.015442,1336.900269 
	C651.389221,1341.485718 647.463745,1340.953491 645.072144,1342.174683 
	C637.992371,1345.789795 630.346375,1348.555542 623.873779,1353.030396 
	C616.690247,1357.996948 615.859558,1368.747437 620.865295,1375.341064 
	C625.752991,1381.779175 634.599365,1382.632690 642.349670,1377.369141 
	C645.045654,1375.538086 647.673096,1373.606201 650.489807,1371.608521 
	C652.526123,1379.169678 654.807800,1381.254883 659.637634,1380.407227 
	C665.422302,1379.391846 671.603027,1373.103760 671.374451,1368.399658 
	C670.915039,1368.641357 670.488525,1368.889893 670.042542,1369.096436 
	C667.926147,1370.076904 665.804321,1371.045654 663.684387,1372.018555 
	C663.107544,1369.855347 662.091064,1367.704102 662.031860,1365.526855 
	C661.805847,1357.207153 661.887939,1348.878906 661.855225,1339.574707 
M1392.701050,1324.159546 
	C1390.593750,1326.505493 1388.224487,1328.667358 1386.423706,1331.228882 
	C1377.830322,1343.452148 1378.949463,1361.932495 1388.867920,1372.438354 
	C1396.244873,1380.252075 1405.335815,1383.061035 1415.685059,1380.280640 
	C1427.819336,1377.020874 1434.568848,1368.464966 1437.278442,1356.480103 
	C1440.205566,1343.532837 1435.378052,1330.001587 1425.461548,1323.154785 
	C1415.483643,1316.265381 1404.475586,1316.461182 1392.701050,1324.159546 
M1640.787964,1326.708862 
	C1634.337524,1320.465698 1626.744995,1317.154053 1617.690186,1318.397949 
	C1597.310913,1321.198120 1585.796143,1344.989136 1595.097656,1364.847412 
	C1604.105713,1384.079224 1628.748535,1387.039917 1641.998169,1370.482300 
	C1652.068359,1357.897705 1651.775635,1340.426758 1640.787964,1326.708862 
M1552.927124,1378.263062 
	C1551.722046,1381.181641 1550.544189,1384.111816 1549.306763,1387.016846 
	C1545.057495,1396.992432 1543.673828,1397.712036 1533.401001,1395.383057 
	C1528.942993,1394.372314 1525.397339,1396.152344 1524.732178,1399.734741 
	C1524.014526,1403.599976 1526.619263,1407.122559 1530.940430,1407.930786 
	C1536.738037,1409.015381 1541.767578,1406.824219 1545.275391,1402.652100 
	C1548.956055,1398.274902 1552.200928,1393.272827 1554.501709,1388.044678 
	C1560.390869,1374.661987 1565.205078,1360.797485 1571.312622,1347.522949 
	C1575.576294,1338.256348 1576.088501,1326.751221 1587.514282,1320.400146 
	C1579.811523,1320.400146 1573.490479,1320.400146 1566.259644,1320.400146 
	C1574.075806,1325.520874 1574.093018,1325.536621 1570.903198,1333.568481 
	C1569.246948,1337.738892 1567.543823,1341.890991 1565.837402,1346.041260 
	C1563.542725,1351.622803 1561.224609,1357.194824 1558.540283,1363.680054 
	C1553.267212,1352.639160 1548.334595,1342.670288 1543.732666,1332.550903 
	C1540.604614,1325.672363 1541.363037,1324.375122 1548.561890,1321.788330 
	C1548.730469,1321.727783 1548.730957,1321.199829 1548.929810,1320.419189 
	C1539.698242,1320.419189 1530.599487,1320.419189 1521.747314,1320.419189 
	C1525.152466,1324.283203 1528.573608,1327.710083 1531.422119,1331.560547 
	C1533.269043,1334.057251 1534.324341,1337.151733 1535.657471,1340.014160 
	C1541.473999,1352.501953 1547.262329,1365.002930 1552.927124,1378.263062 
M1572.205200,1141.500000 
	C1572.205200,1131.259155 1572.205200,1121.018311 1572.205200,1109.297363 
	C1562.035278,1113.313721 1553.083252,1116.849121 1544.131226,1120.384521 
	C1544.145996,1120.952271 1544.160645,1121.520142 1544.175293,1122.087891 
	C1545.437622,1122.371826 1546.730347,1122.994385 1547.957153,1122.883789 
	C1555.159058,1122.234619 1556.803223,1123.274414 1556.951538,1130.470947 
	C1557.287598,1146.773682 1557.464111,1163.085205 1557.328857,1179.390015 
	C1557.253174,1188.518677 1556.293213,1189.198608 1547.315674,1191.036865 
	C1546.537720,1191.196289 1545.885986,1191.972900 1545.175537,1192.462646 
	C1545.812500,1193.028198 1546.445190,1194.081787 1547.086914,1194.087158 
	C1558.903076,1194.186523 1570.720703,1194.187622 1582.536865,1194.091187 
	C1583.219971,1194.085693 1583.894897,1193.071411 1584.573608,1192.526733 
	C1583.875610,1192.017334 1583.237671,1191.193726 1582.470337,1191.046753 
	C1573.754028,1189.377563 1572.926147,1188.892822 1572.608521,1179.935791 
	C1572.166382,1167.468140 1572.311401,1154.979614 1572.205200,1141.500000 
M1296.859985,1114.449463 
	C1293.002075,1116.003174 1289.096313,1117.452148 1285.315674,1119.175171 
	C1284.208618,1119.679688 1283.456787,1120.963501 1282.542236,1121.890137 
	C1283.797974,1122.219604 1285.088379,1122.927124 1286.303467,1122.815308 
	C1292.834595,1122.214233 1294.787598,1123.429688 1294.899658,1129.846924 
	C1295.196045,1146.830444 1295.360474,1163.822266 1295.159790,1180.805786 
	C1295.067627,1188.597900 1293.432861,1189.834106 1285.826172,1191.026123 
	C1284.891357,1191.172852 1284.056641,1191.958374 1283.175537,1192.447754 
	C1284.141357,1193.027710 1285.101807,1194.101440 1286.073730,1194.111816 
	C1297.234985,1194.231812 1308.398438,1194.233398 1319.559814,1194.118896 
	C1320.572754,1194.108521 1321.575195,1193.073853 1322.582397,1192.514771 
	C1321.545410,1192.002686 1320.544678,1191.149780 1319.465332,1191.034790 
	C1313.142700,1190.360962 1311.722290,1189.131958 1310.811768,1182.948853 
	C1310.570068,1181.307495 1310.369629,1179.641724 1310.363159,1177.986328 
	C1310.283569,1157.495361 1310.243164,1137.004150 1310.193848,1116.513062 
	C1310.189209,1114.598145 1310.193237,1112.683350 1310.193237,1109.533813 
	C1305.230835,1111.342529 1301.397583,1112.739624 1296.859985,1114.449463 
M1347.468994,1371.436890 
	C1346.475220,1377.243774 1341.058105,1376.515259 1337.340454,1378.873535 
	C1349.056885,1378.873535 1360.773438,1378.873535 1372.489868,1378.873535 
	C1372.516235,1378.444946 1372.542603,1378.016357 1372.568970,1377.587769 
	C1371.210205,1377.435425 1369.855103,1377.210205 1368.492188,1377.143188 
	C1362.088867,1376.828613 1359.832886,1375.590698 1359.645630,1369.520020 
	C1359.190674,1354.771729 1359.497559,1340.000000 1359.497559,1324.547607 
	C1363.223267,1324.547607 1366.849976,1324.761475 1370.428833,1324.441162 
	C1371.842041,1324.314819 1373.148682,1322.998169 1374.503906,1322.223511 
	C1373.210327,1321.415649 1371.967041,1320.027954 1370.613647,1319.911011 
	C1366.892212,1319.588989 1363.124756,1319.799805 1359.433838,1319.799805 
	C1359.433838,1313.633057 1359.255493,1308.314453 1359.487915,1303.013794 
	C1359.688232,1298.447388 1360.337769,1293.729980 1365.692261,1292.190308 
	C1370.896118,1290.694092 1374.160767,1293.827637 1376.916016,1297.690430 
	C1377.398682,1298.367310 1377.895264,1299.037231 1378.427734,1299.675049 
	C1381.698975,1303.593506 1385.022217,1304.332886 1387.724487,1301.759277 
	C1390.442383,1299.170776 1389.799194,1294.968872 1386.126465,1291.803833 
	C1385.254761,1291.052612 1384.241211,1290.433960 1383.219360,1289.893677 
	C1368.353394,1282.032715 1351.649414,1291.354248 1348.962280,1309.044434 
	C1348.444458,1312.452271 1348.201904,1315.901855 1347.777832,1319.837524 
	C1344.069336,1319.837524 1340.653687,1319.837524 1336.553345,1319.837524 
	C1337.823242,1328.163818 1344.903809,1322.182251 1347.758057,1325.554443 
	C1347.758057,1340.423218 1347.758057,1355.526367 1347.468994,1371.436890 
M1194.165527,1363.155884 
	C1194.854370,1364.669067 1195.459106,1366.227783 1196.245728,1367.688232 
	C1201.614624,1377.656372 1211.903687,1382.835693 1222.485474,1380.943237 
	C1231.169800,1379.390381 1240.387207,1369.635620 1241.974365,1360.208740 
	C1242.136475,1359.246216 1241.781128,1358.196777 1241.677490,1357.289673 
	C1237.947388,1361.316040 1234.930298,1365.755127 1230.833984,1368.685303 
	C1226.057495,1372.101929 1220.296753,1371.163940 1215.056152,1368.800049 
	C1206.576904,1364.975464 1200.719482,1353.165283 1202.119019,1342.146484 
	C1215.382324,1342.146484 1228.756104,1342.146484 1242.415894,1342.146484 
	C1242.080811,1332.059570 1238.646606,1324.389160 1229.857422,1320.413574 
	C1220.258179,1316.071533 1210.580933,1317.086304 1202.784302,1324.204956 
	C1191.320190,1334.672119 1190.202026,1348.108276 1194.165527,1363.155884 
M802.006714,1367.731934 
	C793.347290,1361.592285 790.563110,1352.869995 790.346863,1342.164307 
	C804.257019,1342.164307 817.512939,1342.164307 830.862671,1342.164307 
	C831.001038,1340.990112 831.152222,1340.355225 831.139099,1339.723755 
	C830.780457,1322.557495 811.765991,1312.557983 795.843201,1321.152954 
	C778.977844,1330.257080 775.596985,1359.885742 789.863281,1374.066895 
	C796.578796,1380.742188 804.730957,1383.055176 813.698608,1380.109741 
	C823.612854,1376.853394 828.724060,1369.062500 831.011047,1359.227539 
	C831.158020,1358.595215 830.699829,1357.822266 830.140503,1355.582886 
	C824.131592,1368.859375 815.770386,1374.448608 802.006714,1367.731934 
M928.034546,1364.341797 
	C933.972656,1376.938354 943.199341,1382.614380 955.130615,1381.010254 
	C964.254272,1379.783813 973.491638,1370.186768 975.315186,1359.942383 
	C975.477905,1359.028442 975.144714,1358.026367 975.067810,1357.307983 
	C971.308167,1361.340332 968.251892,1365.771973 964.135193,1368.725708 
	C959.546021,1372.018555 953.950623,1371.157837 948.816528,1368.985596 
	C940.144043,1365.316040 934.078308,1353.444946 935.442688,1342.168213 
	C948.733032,1342.168213 962.119690,1342.168213 975.450745,1342.168213 
	C976.023621,1331.712402 970.422791,1322.905029 961.558411,1319.618652 
	C951.376160,1315.843750 939.227051,1319.033936 932.752808,1327.594849 
	C924.432983,1338.596558 923.861145,1350.835327 928.034546,1364.341797 
M530.156250,1362.555054 
	C524.447510,1370.453613 517.090393,1372.658325 508.376495,1369.081787 
	C499.452362,1365.418823 493.094055,1353.258545 494.671387,1342.094360 
	C507.969208,1342.094360 521.367310,1342.094360 535.068481,1342.094360 
	C534.643982,1332.132812 531.196289,1324.467773 522.458069,1320.443115 
	C512.857666,1316.021240 503.463043,1317.379517 495.351074,1324.142944 
	C484.680267,1333.039795 481.417206,1352.153931 487.845947,1365.577515 
	C493.245422,1376.851929 502.194366,1382.199707 513.736084,1381.049316 
	C522.819336,1380.143921 531.625427,1371.641724 534.339417,1361.050537 
	C534.652100,1359.830200 534.525024,1358.497314 534.601868,1357.216553 
	C534.140503,1357.105103 533.679199,1356.993652 533.217896,1356.882202 
	C532.328369,1358.570679 531.438782,1360.259033 530.156250,1362.555054 
M901.159973,1376.100708 
	C886.011841,1380.564453 881.167664,1369.856567 875.763062,1359.201050 
	C875.763062,1366.077515 875.763062,1372.954102 875.763062,1379.134888 
	C879.755127,1379.524780 883.251709,1379.879028 886.750854,1380.204956 
	C890.379395,1380.542969 894.019348,1381.198486 897.638672,1381.105835 
	C905.802490,1380.897095 913.156555,1375.123413 915.117737,1367.825439 
	C917.175903,1360.166504 914.511536,1353.677490 906.858887,1348.765747 
	C903.378113,1346.531738 899.497803,1344.928223 895.851318,1342.942017 
	C892.791199,1341.275391 889.181519,1340.028931 886.971313,1337.561035 
	C884.895203,1335.243042 882.858276,1331.191040 883.522461,1328.598389 
	C884.178162,1326.038940 887.944641,1323.327271 890.865601,1322.486816 
	C897.848389,1320.477905 903.646179,1324.257202 906.919556,1331.823242 
	C907.846863,1333.966797 908.716125,1336.135620 909.612061,1338.292725 
	C910.017029,1338.176636 910.422058,1338.060547 910.827026,1337.944458 
	C910.827026,1331.373413 910.827026,1324.802368 910.827026,1319.236084 
	C907.073242,1319.236084 903.724670,1319.339355 900.387268,1319.192017 
	C898.744690,1319.119507 897.132507,1318.518188 895.489624,1318.267456 
	C886.757812,1316.934448 878.462280,1321.961426 875.883972,1330.112183 
	C873.425293,1337.884766 875.675110,1344.329224 883.016296,1348.989136 
	C889.040405,1352.813232 895.639404,1355.774902 901.461487,1359.862427 
	C907.772766,1364.292847 907.633606,1370.594727 901.159973,1376.100708 
M1161.066772,1381.019165 
	C1173.430542,1378.081909 1184.109863,1365.158203 1181.208252,1355.922852 
	C1179.690308,1358.462280 1178.463501,1360.768555 1177.003052,1362.915771 
	C1169.430298,1374.048462 1155.269043,1373.936401 1148.116821,1362.512329 
	C1143.132812,1354.551392 1141.658813,1345.725952 1144.053833,1336.616577 
	C1144.953369,1333.194702 1146.690796,1329.748779 1148.876831,1326.966675 
	C1152.133789,1322.821655 1156.947021,1321.863770 1162.008545,1323.049316 
	C1166.628540,1324.131226 1167.275757,1328.079102 1167.917236,1331.927490 
	C1167.999390,1332.419556 1168.036377,1332.927124 1168.185913,1333.398560 
	C1169.350830,1337.071289 1173.842896,1339.120850 1177.524048,1337.502319 
	C1181.400146,1335.798096 1182.172241,1332.372314 1180.235718,1329.235962 
	C1178.304443,1326.108154 1175.612915,1322.995117 1172.501099,1321.139648 
	C1164.548462,1316.397949 1156.145752,1317.184448 1148.204712,1321.425903 
	C1133.456299,1329.303101 1127.927002,1349.969971 1136.229980,1366.569824 
	C1141.079834,1376.266113 1148.721069,1381.990112 1161.066772,1381.019165 
M1289.095215,1327.529663 
	C1289.095215,1324.444458 1289.095215,1321.359131 1289.095215,1319.167725 
	C1284.825439,1319.167725 1281.288574,1319.266602 1277.761719,1319.123779 
	C1276.118042,1319.057373 1274.505371,1318.428955 1272.858032,1318.214722 
	C1261.662598,1316.757935 1252.651489,1325.197266 1252.929077,1336.473999 
	C1253.114624,1344.005249 1258.161865,1347.595703 1263.702026,1350.890259 
	C1268.844482,1353.948486 1274.268188,1356.560913 1279.254517,1359.845581 
	C1283.169800,1362.424683 1284.935181,1366.436890 1283.377563,1371.161987 
	C1281.941284,1375.518677 1277.490967,1377.793945 1271.977051,1377.167236 
	C1263.617676,1376.217285 1259.269897,1370.649902 1256.459473,1363.389404 
	C1255.879761,1361.891846 1255.233521,1360.419922 1254.618042,1358.936279 
	C1254.219238,1359.054810 1253.820557,1359.173340 1253.421875,1359.291870 
	C1253.421875,1366.121338 1253.421875,1372.950928 1253.421875,1378.968994 
	C1257.709839,1379.511475 1261.678833,1380.133545 1265.671631,1380.483765 
	C1269.470337,1380.816895 1273.338867,1381.317383 1277.097534,1380.945190 
	C1284.831299,1380.179321 1291.593384,1374.055054 1293.097656,1367.120728 
	C1294.740234,1359.549072 1291.925293,1353.383301 1284.434448,1348.610596 
	C1281.916748,1347.006592 1279.162964,1345.776367 1276.537598,1344.337891 
	C1272.605347,1342.183228 1268.604858,1340.134888 1264.793823,1337.781738 
	C1261.279907,1335.611694 1259.522217,1331.746826 1261.207397,1328.338013 
	C1262.520020,1325.682983 1265.953369,1323.192139 1268.913940,1322.412231 
	C1276.011230,1320.542603 1281.705200,1324.579224 1284.995972,1332.600586 
	C1285.780518,1334.512939 1286.686279,1336.375610 1287.536255,1338.261230 
	C1288.055664,1338.099609 1288.575195,1337.938110 1289.094604,1337.776611 
	C1289.094604,1334.681030 1289.094604,1331.585571 1289.095215,1327.529663 
M549.611511,1376.226074 
	C547.741333,1376.788452 545.871094,1377.350830 544.000916,1377.913330 
	C544.076111,1378.199219 544.151245,1378.485229 544.226379,1378.771118 
	C553.746887,1378.771118 563.267334,1378.771118 572.787842,1378.771118 
	C572.159058,1377.478760 571.417908,1377.072021 570.685547,1377.087524 
	C565.657104,1377.193115 563.990601,1374.247070 563.896484,1369.797241 
	C563.843628,1367.302124 563.734863,1364.807129 563.733582,1362.312134 
	C563.721985,1339.177979 563.728271,1316.043701 563.729309,1292.909546 
	C563.729370,1291.000854 563.729309,1289.092163 563.729309,1285.733643 
	C556.050110,1288.826660 549.588257,1291.429443 543.126465,1294.032104 
	C543.252319,1294.515381 543.378174,1294.998657 543.504028,1295.481812 
	C544.234436,1295.626587 545.041321,1296.048584 545.683167,1295.871826 
	C550.800354,1294.462524 552.276794,1297.268311 552.524841,1301.604248 
	C552.600891,1302.932861 552.776123,1304.260132 552.777466,1305.588135 
	C552.798523,1326.392212 552.885925,1347.197021 552.693481,1367.999268 
	C552.669250,1370.616943 551.146118,1373.220703 549.611511,1376.226074 
M760.788635,1286.957031 
	C754.889038,1289.335693 748.989441,1291.714355 743.089844,1294.093018 
	C744.202332,1295.465820 745.152588,1296.031250 745.900085,1295.832886 
	C750.782043,1294.537109 752.204956,1297.208740 752.477478,1301.324097 
	C752.631348,1303.646851 752.877258,1305.971069 752.881348,1308.294922 
	C752.915222,1327.597046 752.924927,1346.899292 752.893188,1366.201416 
	C752.879272,1374.677979 752.336182,1375.336792 744.536987,1377.577148 
	C744.355469,1377.629395 744.305298,1378.138916 744.042786,1378.836426 
	C753.749023,1378.836426 763.287354,1378.836426 773.031372,1378.836426 
	C772.764038,1377.983643 772.759949,1377.741089 772.659912,1377.691040 
	C772.223450,1377.473511 771.752258,1377.138306 771.305969,1377.156006 
	C765.518005,1377.385254 763.786743,1373.999268 763.835632,1368.930176 
	C763.856445,1366.768555 763.729065,1364.605957 763.728333,1362.443726 
	C763.720825,1338.981567 763.769287,1315.519287 763.635742,1292.057861 
	C763.625916,1290.323975 762.329590,1288.597534 760.788635,1286.957031 
M1468.662476,1330.240845 
	C1471.426636,1325.900757 1474.343628,1327.431641 1477.905884,1329.446167 
	C1479.897705,1330.572510 1483.129150,1330.914551 1485.167114,1330.072876 
	C1486.606323,1329.478516 1488.120728,1325.879150 1487.563354,1324.378296 
	C1486.706909,1322.072754 1484.437500,1319.628662 1482.163086,1318.655151 
	C1477.794922,1316.784912 1474.110596,1319.294678 1471.066528,1322.361938 
	C1469.032227,1324.411621 1467.281982,1326.743286 1464.646851,1329.838989 
	C1464.434082,1325.199463 1464.273560,1321.699463 1464.061523,1317.079346 
	C1456.696777,1320.089722 1450.212891,1322.740234 1443.729004,1325.390625 
	C1453.433960,1328.317627 1453.493530,1328.316895 1453.616821,1338.936646 
	C1453.713501,1347.254639 1453.641846,1355.574585 1453.639893,1363.893677 
	C1453.637695,1374.095947 1453.634888,1374.094849 1444.647095,1377.924805 
	C1444.553467,1377.964966 1444.556519,1378.231812 1444.400635,1378.827393 
	C1454.571899,1378.827393 1464.620117,1378.827393 1474.668335,1378.827393 
	C1474.271240,1377.580811 1473.766235,1377.180786 1473.202271,1377.062256 
	C1466.010620,1375.549805 1464.920776,1374.611572 1464.597900,1367.227173 
	C1464.249512,1359.255615 1464.597656,1351.254395 1464.278687,1343.280518 
	C1464.088989,1338.539917 1465.078979,1334.406982 1468.662476,1330.240845 
M473.210297,1318.145142 
	C465.158051,1317.746826 462.075989,1324.637207 456.805481,1330.170410 
	C456.805481,1325.435181 456.805481,1321.856201 456.805481,1316.959595 
	C449.375488,1320.015625 442.828033,1322.708496 436.280609,1325.401489 
	C436.464081,1325.834229 436.647552,1326.267090 436.831024,1326.699829 
	C445.812958,1327.584106 445.939362,1327.673828 446.013977,1337.295532 
	C446.083679,1346.288818 446.038788,1355.282837 446.039490,1364.276611 
	C446.040283,1374.220703 446.037415,1374.219604 437.243164,1377.885254 
	C437.157440,1377.921021 437.171387,1378.196045 437.138824,1378.359375 
	C441.515259,1380.359009 464.416321,1379.995850 466.919891,1377.886963 
	C456.986328,1374.311157 456.978119,1374.307861 456.961426,1362.802979 
	C456.950348,1355.141602 456.231262,1347.382080 457.246185,1339.857544 
	C457.808319,1335.689697 460.856873,1331.544922 463.735107,1328.210083 
	C464.503723,1327.319702 468.771088,1329.446777 471.429932,1330.196289 
	C471.585358,1330.240112 471.735016,1330.446167 471.862610,1330.425171 
	C474.272369,1330.031982 477.355103,1330.395142 478.930725,1329.026733 
	C481.456726,1326.833008 480.944855,1323.447144 478.609467,1321.004883 
	C477.419861,1319.760742 475.544312,1319.172607 473.210297,1318.145142 
M1104.806519,1341.772583 
	C1104.805786,1349.767944 1104.832642,1357.763550 1104.796753,1365.758789 
	C1104.755859,1374.880249 1104.604736,1375.051636 1096.391724,1377.626709 
	C1096.211914,1377.682983 1096.177856,1378.204346 1095.975464,1378.807983 
	C1105.490601,1378.807983 1114.888550,1378.807983 1124.286499,1378.807983 
	C1115.978149,1374.749268 1115.930664,1374.750122 1115.755249,1364.872925 
	C1115.651855,1359.044800 1115.724609,1353.213379 1115.723267,1347.383423 
	C1115.720947,1337.746338 1115.722656,1328.109131 1115.722656,1316.948120 
	C1108.178223,1319.979004 1101.562622,1322.636841 1094.947021,1325.294556 
	C1104.409790,1328.709717 1104.410400,1328.709717 1104.794067,1339.333496 
	C1104.812134,1339.832642 1104.802368,1340.332764 1104.806519,1341.772583 
M600.878723,1367.262817 
	C600.878723,1350.981079 600.878723,1334.699463 600.878723,1316.851929 
	C593.291077,1319.943970 586.694031,1322.632324 580.097046,1325.320679 
	C589.687805,1328.558838 589.752625,1328.558105 589.867615,1339.395264 
	C589.964783,1348.548950 589.937317,1357.704956 589.874268,1366.859253 
	C589.820618,1374.648071 589.547546,1374.910400 581.821899,1377.287842 
	C581.466187,1377.397217 581.265686,1378.010742 580.607178,1378.925049 
	C590.564209,1378.925049 600.037964,1378.925049 609.511719,1378.925049 
	C609.848022,1378.434814 610.184265,1377.944702 610.520569,1377.454468 
	C603.203308,1377.857544 600.451843,1374.391479 600.878723,1367.262817 
M1573.629272,1075.097778 
	C1572.542603,1070.861084 1569.586670,1067.963867 1565.491455,1068.451660 
	C1562.484497,1068.809937 1558.616455,1070.872925 1557.239746,1073.348389 
	C1555.984619,1075.605103 1556.776978,1079.829224 1558.088745,1082.483643 
	C1559.898926,1086.145996 1563.956055,1086.816284 1567.712158,1085.578979 
	C1572.211914,1084.097046 1574.135376,1080.583740 1573.629272,1075.097778 
M1299.596069,1068.850830 
	C1293.338257,1072.727661 1292.042114,1079.299316 1296.814819,1083.611450 
	C1300.169556,1086.642456 1304.064453,1086.989990 1307.799316,1084.487305 
	C1311.526245,1081.990112 1312.669556,1078.308594 1311.187256,1074.006470 
	C1309.661743,1069.579346 1306.143799,1067.878174 1299.596069,1068.850830 
M411.576508,1375.114990 
	C413.761841,1381.099854 419.138000,1383.515137 422.755310,1379.612793 
	C424.504028,1377.726196 425.575928,1373.728271 424.790070,1371.424438 
	C424.105438,1369.417236 420.446198,1367.403931 417.985016,1367.249634 
	C413.729065,1366.982910 411.701630,1370.219849 411.576508,1375.114990 
M378.909515,1377.414551 
	C380.673615,1380.565796 383.569702,1382.042725 386.802673,1380.884277 
	C388.836121,1380.155640 391.525970,1377.967407 391.721344,1376.191895 
	C391.997009,1373.687012 390.917114,1370.234009 389.130402,1368.555664 
	C387.720398,1367.230957 384.024902,1367.132324 381.957794,1367.981812 
	C378.362854,1369.458984 377.229828,1372.825684 378.909515,1377.414551 
M347.104614,1379.476440 
	C352.886353,1382.659790 358.600555,1380.772217 358.725342,1375.174316 
	C358.779938,1372.726562 356.985138,1369.100952 354.972260,1368.064575 
	C352.971893,1367.034668 349.010895,1367.729492 347.038513,1369.191040 
	C343.651642,1371.701172 344.114136,1375.505493 347.104614,1379.476440 
M600.337708,1289.203857 
	C595.233887,1285.635620 589.682495,1286.861084 589.215515,1291.992798 
	C589.014282,1294.204468 590.259827,1297.696045 591.943298,1298.628540 
	C593.867615,1299.694092 597.499329,1299.382080 599.509949,1298.188110 
	C602.558350,1296.377930 602.565491,1292.971191 600.337708,1289.203857 
M1115.157227,1297.691284 
	C1117.806396,1292.553833 1116.397583,1287.636963 1111.697632,1287.328247 
	C1109.372803,1287.175415 1105.804932,1288.631714 1104.805420,1290.458008 
	C1103.793945,1292.306152 1104.431030,1296.139526 1105.827759,1297.976440 
	C1108.091431,1300.953247 1111.602783,1300.690430 1115.157227,1297.691284 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1539.999756,1011.333130 
	C1105.882324,1011.333191 672.264893,1011.333191 238.292465,1011.333191 
	C238.292465,996.170349 238.292465,981.418091 238.292465,966.200684 
	C771.552551,966.200684 1304.895752,966.200684 1838.595459,966.200684 
	C1838.595459,981.033325 1838.595459,995.911804 1838.595459,1011.333130 
	C1739.068359,1011.333130 1639.784180,1011.333130 1539.999756,1011.333130 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M1169.648193,865.644287 
	C1160.353760,874.626465 1149.152832,878.570068 1136.990479,879.863525 
	C1127.398315,880.883728 1117.734619,881.671021 1108.096680,881.740051 
	C1076.099121,881.969177 1044.098877,881.828613 1012.099670,881.818909 
	C1011.293945,881.818665 1010.488220,881.691406 1009.353333,881.595642 
	C1009.353333,785.188660 1009.353333,688.975220 1009.353333,592.499939 
	C1010.274414,592.380127 1011.067078,592.175415 1011.855957,592.189026 
	C1046.827148,592.791138 1081.815063,592.966492 1116.758789,594.300598 
	C1127.385132,594.706360 1138.369263,597.019653 1148.351318,600.720276 
	C1170.444458,608.910767 1180.586304,626.440125 1181.890137,649.378906 
	C1182.598877,661.849121 1183.068848,674.350220 1183.097290,686.838684 
	C1183.193726,729.169678 1183.224854,771.502747 1182.862305,813.831421 
	C1182.772827,824.277039 1182.115967,834.941162 1179.806519,845.068481 
	C1178.174805,852.224426 1173.316284,858.644592 1169.648193,865.644287 
M1084.056763,789.500000 
	C1084.056763,803.594666 1084.056763,817.689270 1084.056763,832.120911 
	C1086.933350,831.994385 1089.092285,832.030945 1091.218018,831.783630 
	C1101.766724,830.556702 1105.159302,827.388306 1106.667603,816.924377 
	C1107.331421,812.319092 1107.778931,807.637695 1107.787842,802.989807 
	C1107.876709,756.689148 1107.857666,710.388184 1107.845581,664.087341 
	C1107.845215,662.260315 1107.654053,660.428894 1107.475830,658.606995 
	C1106.136353,644.914551 1098.280273,639.490234 1084.056763,642.757507 
	C1084.056763,691.281189 1084.056763,739.890625 1084.056763,789.500000 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M1348.995361,643.956421 
	C1360.780029,723.251953 1372.528687,802.107605 1384.336792,881.363770 
	C1382.550171,881.559326 1381.292480,881.815674 1380.034424,881.817017 
	C1357.368774,881.841919 1334.702759,881.758911 1312.038086,881.914795 
	C1308.397339,881.939819 1307.179932,880.924500 1306.961670,877.211304 
	C1306.121704,862.911011 1304.848755,848.636536 1303.916138,834.340820 
	C1303.704468,831.097412 1302.822388,829.578186 1299.223022,829.738281 
	C1293.068726,830.011902 1286.885742,830.002808 1280.730225,829.745667 
	C1277.193604,829.597900 1276.238037,830.974121 1275.987549,834.302917 
	C1274.813599,849.906311 1273.388428,865.490845 1272.021973,881.444397 
	C1246.180664,881.444397 1220.630127,881.444397 1194.354370,881.444397 
	C1195.338501,873.501587 1196.253906,865.655701 1197.290039,857.825745 
	C1203.887207,807.969604 1210.506836,758.116577 1217.126831,708.263489 
	C1222.080811,670.956055 1227.080078,633.654480 1231.944946,596.335449 
	C1232.343872,593.275085 1233.298706,592.089294 1236.556641,592.099854 
	C1270.054688,592.208557 1303.553467,592.215942 1337.051514,592.099365 
	C1340.497803,592.087402 1341.577759,593.314026 1342.030396,596.572327 
	C1344.207153,612.238892 1346.626221,627.871704 1348.995361,643.956421 
M1295.396240,720.014893 
	C1293.700073,700.586121 1292.004028,681.157349 1290.307983,661.728638 
	C1289.837158,661.732056 1289.366333,661.735535 1288.895630,661.739014 
	C1284.274902,700.298706 1279.654297,738.858337 1274.983887,777.833923 
	C1284.468140,777.833923 1292.659912,777.833923 1301.200439,777.833923 
	C1299.282349,758.599670 1297.403687,739.761475 1295.396240,720.014893 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M1529.967773,760.942871 
	C1537.425659,704.581177 1544.843384,648.662354 1552.296265,592.479492 
	C1588.565430,592.479492 1624.485718,592.479492 1661.076416,592.479492 
	C1675.375610,688.682556 1689.680908,784.926880 1704.040161,881.534424 
	C1678.330322,881.534424 1653.082031,881.534424 1627.248657,881.534424 
	C1625.868286,864.455139 1624.492920,847.439026 1623.094360,830.136597 
	C1614.179077,830.136597 1605.578003,830.136597 1596.320190,830.136597 
	C1595.621216,837.441284 1594.880981,844.822998 1594.216553,852.211426 
	C1593.440674,860.837097 1592.683838,869.465149 1592.025391,878.100281 
	C1591.855469,880.329590 1591.576294,881.874695 1588.711792,881.866272 
	C1564.075317,881.793640 1539.438599,881.824524 1513.918335,881.824524 
	C1519.296143,841.367432 1524.611938,801.376526 1529.967773,760.942871 
M1602.838745,709.235962 
	C1600.193848,731.977478 1597.548950,754.718933 1594.867798,777.771484 
	C1604.155151,777.771484 1612.495605,777.771484 1620.769287,777.771484 
	C1617.055054,738.521057 1613.380615,699.692078 1609.706177,660.863098 
	C1609.483032,660.864075 1609.259888,660.865112 1609.036743,660.866089 
	C1606.997803,676.691284 1604.958984,692.516479 1602.838745,709.235962 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M450.201721,713.758179 
	C450.164581,695.122131 450.207672,676.960327 450.037537,658.800476 
	C449.992432,653.987671 449.530273,649.145874 448.830872,644.380310 
	C447.962097,638.460876 444.385193,635.617188 438.136627,635.245728 
	C431.500824,634.851257 426.323242,637.522339 425.211365,643.039307 
	C423.936554,649.364929 423.110718,655.895752 423.089172,662.340820 
	C422.922058,712.326233 422.929535,762.312622 423.082214,812.298157 
	C423.100891,818.412842 423.897461,824.601929 425.067139,830.613831 
	C426.145325,836.155457 430.504700,838.822205 436.621613,838.819763 
	C442.445068,838.817444 446.722931,836.260254 447.957794,830.930725 
	C449.151031,825.780701 449.819000,820.415100 449.933746,815.127136 
	C450.244598,800.804199 450.140015,786.472107 450.197906,772.143677 
	C450.205200,770.333374 450.198883,768.523071 450.198883,766.263794 
	C475.249023,766.263794 499.944366,766.263794 525.295166,766.263794 
	C525.295166,772.491333 525.541443,778.750244 525.243713,784.983154 
	C524.632263,797.781860 523.945190,810.584229 522.900696,823.353394 
	C520.848511,848.442627 506.788116,865.364014 485.410736,876.932495 
	C469.459717,885.564636 452.137024,888.107788 434.220764,887.575256 
	C418.813721,887.117249 403.804321,884.945740 389.459351,878.794128 
	C366.882019,869.112000 354.132599,851.894104 351.237244,827.910889 
	C349.186584,810.924500 348.032135,793.745178 347.841064,776.636719 
	C347.441162,740.821533 347.674957,704.992126 348.240875,669.177917 
	C348.402618,658.941833 349.350494,648.384949 352.118805,638.591736 
	C358.097748,617.440552 372.366364,602.690308 392.584198,594.566345 
	C422.154846,582.684387 452.192657,582.975098 481.639740,594.971375 
	C505.354980,604.632751 520.402649,622.278503 522.631836,648.276062 
	C524.563049,670.798035 524.552979,693.486633 525.378174,716.102417 
	C525.395020,716.563660 525.132935,717.035095 524.898804,717.863220 
	C523.521484,717.961670 522.073547,718.153931 520.625427,718.155334 
	C498.798035,718.175781 476.970581,718.153137 455.143250,718.194580 
	C452.316101,718.199951 449.551422,718.428406 450.201721,713.758179 
z"/>
<path fill="#FCFEFC" opacity="1.000000" stroke="none" 
	d="
M907.728760,694.510132 
	C913.924805,660.211365 920.046204,626.326782 926.173401,592.410217 
	C949.950806,592.410217 972.563232,592.410217 995.854553,592.410217 
	C995.407227,594.253967 995.075989,595.939758 994.588989,597.579285 
	C977.296570,655.800476 959.966675,714.010620 942.744934,772.252686 
	C941.772339,775.541931 941.245300,779.087585 941.232483,782.516541 
	C941.117310,813.345642 941.163879,844.175354 941.162903,875.004883 
	C941.162842,876.991333 941.162842,878.977783 941.162842,881.400513 
	C917.959839,881.400513 895.089966,881.400513 871.484436,881.400513 
	C871.484436,879.443970 871.484436,877.498718 871.484436,875.553467 
	C871.484070,844.890564 871.843872,814.220703 871.245117,783.569458 
	C871.083191,775.281067 868.502930,766.911865 866.207336,758.813721 
	C851.005188,705.184692 835.576111,651.619934 820.233643,598.030640 
	C819.742188,596.314148 819.388123,594.558350 818.879578,592.444763 
	C841.953674,592.444763 864.542786,592.444763 888.274048,592.444763 
	C894.316711,628.496582 900.374268,664.637573 906.504028,701.209106 
	C906.924988,698.908813 907.289551,696.916626 907.728760,694.510132 
z"/>
<path fill="#FCFDFC" opacity="1.000000" stroke="none" 
	d="
M764.799744,879.535828 
	C763.943298,880.599915 763.114258,881.730225 762.280823,881.733521 
	C738.301208,881.828308 714.321167,881.810120 689.631104,881.810120 
	C689.631104,804.616516 689.631104,727.742065 689.631104,650.326111 
	C674.515381,650.326111 660.095093,650.326111 645.342163,650.326111 
	C645.342163,630.864746 645.342163,611.943787 645.342163,592.590698 
	C699.787598,592.590698 754.306946,592.590698 809.154480,592.590698 
	C809.154480,611.591980 809.154480,630.512939 809.154480,650.105957 
	C794.491211,650.105957 779.948059,650.105957 764.825073,650.105957 
	C764.825073,726.875610 764.825073,802.976196 764.799744,879.535828 
z"/>
<path fill="#FCFEFC" opacity="1.000000" stroke="none" 
	d="
M1408.509277,827.999512 
	C1408.508911,771.017029 1408.520142,714.534546 1408.496216,658.052063 
	C1408.492310,649.006348 1409.381470,650.241211 1400.386597,650.189697 
	C1390.056763,650.130554 1379.726440,650.185425 1369.396240,650.164429 
	C1367.775879,650.161133 1366.155640,650.018127 1364.282104,649.927429 
	C1364.282104,630.698730 1364.282104,611.783875 1364.282104,592.515503 
	C1418.827393,592.515503 1473.229370,592.515503 1528.079834,592.515503 
	C1528.079834,611.526611 1528.079834,630.562744 1528.079834,650.086792 
	C1513.521484,650.086792 1499.092529,650.086792 1483.994019,650.086792 
	C1483.994019,727.413513 1483.994019,804.260315 1483.994019,881.470581 
	C1458.603516,881.470581 1433.888428,881.470581 1408.509277,881.470581 
	C1408.509277,863.730591 1408.509277,846.115051 1408.509277,827.999512 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M563.060608,881.836243 
	C559.440430,881.836121 556.309265,881.836121 552.857056,881.836121 
	C552.857056,785.127014 552.857056,688.908691 552.857056,592.426819 
	C577.762329,592.426819 602.358826,592.426819 627.390015,592.426819 
	C627.390015,688.721497 627.390015,784.908142 627.390015,881.836365 
	C606.071655,881.836365 584.810608,881.836365 563.060608,881.836243 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M1016.580383,1129.826416 
	C1012.773376,1121.978394 1007.004395,1119.134155 999.288879,1122.097778 
	C994.573547,1123.909058 990.254456,1126.924194 986.070374,1129.846680 
	C984.709656,1130.797241 983.641418,1133.160522 983.626465,1134.887939 
	C983.495605,1150.042236 983.426697,1165.203857 983.771851,1180.353271 
	C983.966125,1188.882324 985.565186,1190.107056 994.240295,1191.003052 
	C995.016968,1191.083252 995.773315,1191.360352 996.864014,1192.731445 
	C995.668518,1193.216187 994.477112,1194.109985 993.276978,1194.121704 
	C982.117615,1194.231201 970.956360,1194.230103 959.796997,1194.123535 
	C958.748535,1194.113525 957.708984,1193.162109 956.665283,1192.647705 
	C957.651917,1192.087280 958.584595,1191.188965 959.633850,1191.021606 
	C966.242615,1189.967407 968.499817,1188.424683 968.590088,1181.857788 
	C968.830261,1164.385254 968.697693,1146.897705 968.206177,1129.430420 
	C968.027527,1123.081543 966.183289,1122.246582 959.777405,1122.798340 
	C958.514221,1122.906982 957.189392,1122.300293 955.893005,1122.023560 
	C956.922791,1121.002319 957.773315,1119.568848 959.012878,1119.029785 
	C964.959045,1116.443848 970.987000,1114.038696 977.039185,1111.707520 
	C978.812439,1111.024536 980.760742,1110.795776 983.362793,1110.187012 
	C983.362793,1115.988647 983.362793,1121.060547 983.362793,1127.652466 
	C987.011963,1124.229858 989.296753,1121.322754 992.248169,1119.488037 
	C997.372314,1116.302368 1002.634521,1112.400269 1008.329651,1111.268066 
	C1019.890137,1108.970215 1026.359985,1113.798096 1032.624146,1127.569092 
	C1035.023315,1124.951416 1037.092773,1122.473145 1039.390625,1120.228760 
	C1045.702271,1114.063477 1052.947754,1110.233765 1062.161499,1110.791626 
	C1072.006470,1111.387451 1079.144165,1117.785278 1080.728027,1129.050049 
	C1081.742554,1136.265869 1081.748779,1143.644287 1081.896729,1150.956177 
	C1082.095581,1160.780273 1081.907227,1170.611572 1082.052246,1180.437256 
	C1082.158447,1187.633179 1084.156372,1189.719116 1091.293457,1191.010254 
	C1092.234985,1191.180664 1093.070435,1191.937744 1093.955078,1192.423096 
	C1093.331787,1193.001343 1092.712402,1194.079468 1092.084717,1194.084351 
	C1079.474243,1194.183105 1066.862671,1194.158203 1054.251221,1194.158203 
	C1054.099854,1193.611938 1053.948364,1193.065674 1053.796997,1192.519287 
	C1054.907227,1192.007202 1055.981201,1191.162476 1057.133545,1191.036987 
	C1064.429565,1190.242798 1066.858521,1188.043701 1066.959229,1180.567627 
	C1067.156616,1165.913696 1067.305542,1151.246948 1066.915283,1136.600464 
	C1066.554077,1123.041870 1057.307617,1117.550903 1045.033447,1123.423950 
	C1041.512573,1125.108887 1038.321289,1127.772217 1035.508423,1130.527588 
	C1034.055542,1131.951050 1033.085571,1134.550293 1033.056274,1136.634521 
	C1032.851929,1151.123413 1032.906128,1165.616943 1032.971558,1180.108398 
	C1033.006958,1187.966797 1034.850220,1189.863647 1042.828125,1191.004028 
	C1043.953857,1191.164917 1045.009644,1191.815674 1046.097778,1192.239746 
	C1045.989502,1192.800171 1045.881348,1193.360596 1045.773071,1193.921021 
	C1032.452026,1193.921021 1019.130798,1193.934937 1005.810425,1193.835815 
	C1005.496155,1193.833496 1005.191223,1192.562256 1004.911255,1191.947266 
	C1005.359863,1191.561646 1005.590393,1191.187378 1005.856873,1191.159912 
	C1017.010193,1190.006104 1018.037109,1188.881592 1018.054382,1177.529297 
	C1018.072693,1165.536011 1018.162415,1153.541382 1017.999573,1141.550293 
	C1017.948120,1137.758911 1017.184570,1133.977051 1016.580383,1129.826416 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M849.584595,1190.707520 
	C850.644592,1190.836182 851.368347,1190.755127 851.956970,1190.999512 
	C853.118530,1191.481689 854.205139,1192.144165 855.322754,1192.732178 
	C854.252197,1193.219238 853.185364,1194.119873 852.110596,1194.129517 
	C840.949707,1194.230713 829.786987,1194.239746 818.626770,1194.107178 
	C817.508118,1194.093872 816.402405,1192.980103 815.290649,1192.377197 
	C816.365173,1191.905884 817.401733,1191.165161 818.520447,1191.007812 
	C824.956970,1190.102051 827.219177,1188.448608 827.300659,1181.743652 
	C827.515015,1164.102173 827.313293,1146.450073 826.921204,1128.810059 
	C826.798584,1123.295044 824.686157,1122.172485 818.943237,1122.723389 
	C817.430786,1122.868408 815.868408,1122.492554 814.328918,1122.355957 
	C814.295105,1121.689087 814.261230,1121.022217 814.227356,1120.355225 
	C823.173340,1116.836182 832.119324,1113.317017 842.019409,1109.422485 
	C842.019409,1115.989136 842.019409,1121.062744 842.019409,1126.136230 
	C842.398193,1126.370239 842.776978,1126.604126 843.155701,1126.838135 
	C845.809265,1124.303467 848.430298,1121.733765 851.123169,1119.241699 
	C856.256897,1114.490967 862.017090,1111.067993 869.262146,1110.827637 
	C879.391968,1110.491577 886.036255,1114.774658 889.856201,1124.151123 
	C890.218811,1125.041138 890.588745,1125.928101 891.445679,1128.005005 
	C894.719910,1124.413940 897.078674,1120.801147 900.355225,1118.488770 
	C904.610229,1115.485718 909.362549,1112.256470 914.310425,1111.343140 
	C928.298767,1108.760620 936.895142,1114.805298 939.359985,1128.898560 
	C940.753357,1136.865479 940.591919,1145.130859 940.805237,1153.270264 
	C941.040771,1162.260376 940.821289,1171.261597 940.954468,1180.255615 
	C941.069336,1188.020142 942.614197,1189.629028 950.367859,1191.019653 
	C951.339233,1191.193970 952.220215,1191.872070 953.143127,1192.316650 
	C953.041870,1192.726074 952.940674,1193.135620 952.839417,1193.545044 
	C951.802368,1193.758179 950.766174,1194.151978 949.728271,1194.156372 
	C938.733276,1194.203979 927.737671,1194.233887 916.743469,1194.131104 
	C915.484497,1194.119385 914.233398,1193.269653 912.978638,1192.808350 
	C914.230347,1192.194458 915.436218,1191.186523 916.741638,1191.034668 
	C922.497803,1190.365356 924.944885,1188.637329 925.430542,1182.692261 
	C926.052551,1175.076416 925.917908,1167.393066 925.970459,1159.737915 
	C926.024170,1151.909424 926.306274,1144.053101 925.781128,1136.256714 
	C924.873352,1122.777832 915.593384,1117.504639 903.486450,1123.507446 
	C896.024719,1127.207153 890.714294,1132.042847 891.479919,1141.985229 
	C892.436401,1154.406982 891.630798,1166.957764 891.779480,1179.450562 
	C891.887207,1188.501709 893.434021,1190.008911 902.432312,1191.004517 
	C903.200317,1191.089478 903.945007,1191.386230 905.009033,1192.775269 
	C903.806152,1193.244629 902.606873,1194.110718 901.399841,1194.121704 
	C889.905823,1194.226685 878.410095,1194.225830 866.916138,1194.122803 
	C865.872437,1194.113525 864.837036,1193.179810 863.797729,1192.675415 
	C864.758301,1192.111206 865.673218,1191.164185 866.686768,1191.043091 
	C874.885071,1190.063965 876.806580,1188.205688 876.875610,1179.992676 
	C876.990479,1166.333008 876.988098,1152.670654 876.835022,1139.011719 
	C876.801941,1136.064697 876.255798,1133.020874 875.363281,1130.206177 
	C873.075989,1122.992676 866.878723,1119.167725 859.769958,1121.652954 
	C854.263428,1123.578003 849.249695,1127.095825 844.319763,1130.362793 
	C843.030945,1131.216797 842.303101,1133.768677 842.283325,1135.551392 
	C842.126831,1149.710083 842.067444,1163.873413 842.279663,1178.030640 
	C842.357788,1183.241699 841.938721,1189.164062 849.584595,1190.707520 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M395.181061,1110.000000 
	C395.181213,1129.992188 395.153412,1149.484375 395.206329,1168.976318 
	C395.216278,1172.633545 395.349365,1176.312988 395.772278,1179.941895 
	C396.753021,1188.357422 399.219360,1190.559937 407.563080,1190.969238 
	C409.010223,1191.040039 410.454742,1191.163452 411.900482,1191.262573 
	C412.040588,1191.715210 412.180695,1192.167847 412.320801,1192.620605 
	C411.185211,1193.143555 410.052643,1194.114136 408.913544,1194.121826 
	C394.086914,1194.222534 379.259216,1194.212036 364.432281,1194.134888 
	C363.293121,1194.129028 362.157654,1193.411621 361.020477,1193.024902 
	C361.088257,1192.500854 361.156067,1191.976807 361.223846,1191.452759 
	C363.230865,1191.258911 365.235229,1191.030762 367.245361,1190.877563 
	C373.811615,1190.376831 376.507751,1188.069336 377.685608,1181.631592 
	C378.042938,1179.678467 378.130890,1177.653564 378.132843,1175.661377 
	C378.162018,1145.673462 378.158417,1115.685547 378.150116,1085.697632 
	C378.149780,1084.556763 378.024689,1083.416016 377.874481,1080.857910 
	C368.942963,1081.648071 360.131378,1081.661743 351.679291,1083.411743 
	C344.604492,1084.876465 341.809601,1091.149902 340.612518,1098.011597 
	C340.361389,1099.451050 338.973297,1100.692139 338.109192,1102.024658 
	C337.678223,1100.580322 336.898254,1099.141724 336.874573,1097.690674 
	C336.749969,1090.046143 336.816223,1082.398560 336.816223,1074.384277 
	C369.880188,1074.384277 402.607971,1074.384277 435.771698,1074.384277 
	C435.771698,1083.412354 435.771698,1092.519409 435.771698,1101.626343 
	C435.394196,1101.721191 435.016724,1101.816162 434.639221,1101.911011 
	C434.146729,1100.162354 433.640808,1098.417358 433.163910,1096.664429 
	C430.488434,1086.829468 425.679138,1082.529663 415.619812,1081.905151 
	C409.035278,1081.496338 402.404785,1081.828003 395.181061,1081.828003 
	C395.181061,1091.265137 395.181061,1100.382568 395.181061,1110.000000 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M1232.286865,1113.084839 
	C1249.635254,1106.237305 1262.250977,1115.288818 1262.928223,1135.125854 
	C1263.411011,1149.270752 1263.190430,1163.439209 1263.328247,1177.596802 
	C1263.345947,1179.418823 1263.619751,1181.239502 1263.797241,1183.058594 
	C1264.297119,1188.180420 1267.068115,1190.784546 1272.267212,1191.041748 
	C1273.437134,1191.099487 1274.559692,1192.116821 1275.703979,1192.692505 
	C1274.475098,1193.192139 1273.250610,1194.113037 1272.016846,1194.125610 
	C1261.023071,1194.237671 1250.027100,1194.233643 1239.033325,1194.124512 
	C1237.853638,1194.112915 1236.682617,1193.214722 1234.999878,1192.517456 
	C1236.156128,1191.558838 1236.386719,1191.199219 1236.649414,1191.174072 
	C1246.937500,1190.191040 1248.732910,1188.549316 1248.749390,1178.266724 
	C1248.772705,1163.785156 1248.931274,1149.269165 1248.021240,1134.831543 
	C1247.271118,1122.933105 1239.386108,1118.539917 1228.450928,1123.411865 
	C1224.166138,1125.320679 1220.448120,1128.664062 1216.800781,1131.743652 
	C1215.670044,1132.698608 1215.165161,1134.932617 1215.151001,1136.587646 
	C1215.028809,1150.746582 1215.010864,1164.907715 1215.114746,1179.067017 
	C1215.182983,1188.367065 1216.516479,1189.686646 1225.764038,1191.045166 
	C1226.635498,1191.173096 1227.389404,1192.101196 1228.197876,1192.657837 
	C1227.321045,1193.166382 1226.447876,1194.110474 1225.567017,1194.117554 
	C1213.906616,1194.213013 1202.244873,1194.211060 1190.584839,1194.098877 
	C1189.783691,1194.091064 1188.992798,1193.012085 1188.197144,1192.431274 
	C1188.918213,1191.950317 1189.583740,1191.185425 1190.368774,1191.032349 
	C1198.276489,1189.490601 1200.022095,1187.980469 1200.117065,1179.981323 
	C1200.310791,1163.661377 1200.226440,1147.333862 1199.948730,1131.014648 
	C1199.814331,1123.122437 1198.587524,1122.401611 1190.545776,1122.938599 
	C1189.403809,1123.014771 1188.212402,1122.347534 1187.043823,1122.024414 
	C1187.022949,1121.497681 1187.001953,1120.970947 1186.981079,1120.444214 
	C1195.999756,1116.853271 1205.018433,1113.262207 1214.927002,1109.316772 
	C1214.927002,1116.001465 1214.927002,1120.954102 1214.927002,1126.260254 
	C1220.856812,1121.715088 1226.392700,1117.471802 1232.286865,1113.084839 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M1761.446289,1189.761719 
	C1764.521484,1190.641479 1767.244019,1191.328125 1769.966553,1192.014648 
	C1769.889282,1192.502686 1769.812012,1192.990723 1769.734741,1193.478760 
	C1768.705200,1193.715088 1767.676758,1194.151733 1766.646118,1194.156616 
	C1755.648560,1194.208130 1744.650146,1194.238403 1733.653442,1194.126953 
	C1732.418701,1194.114502 1731.192993,1193.192871 1729.963135,1192.692871 
	C1731.101318,1192.119629 1732.197144,1191.169067 1733.384644,1191.035522 
	C1739.959106,1190.296143 1742.351929,1188.703735 1742.450073,1182.113525 
	C1742.690552,1165.973633 1742.745117,1149.800415 1741.940186,1133.687134 
	C1741.454102,1123.957397 1735.561279,1120.232788 1726.141846,1122.165649 
	C1719.499023,1123.528687 1714.385864,1127.516968 1710.061035,1132.517334 
	C1709.314819,1133.380127 1709.129395,1134.953125 1709.126587,1136.199219 
	C1709.095703,1150.362427 1709.030884,1164.527344 1709.229492,1178.688354 
	C1709.340576,1186.620972 1709.982422,1191.451294 1719.981079,1191.069336 
	C1720.680420,1191.042725 1721.416260,1191.968140 1722.135132,1192.450928 
	C1721.491577,1193.020630 1720.851685,1194.082153 1720.203613,1194.087158 
	C1707.586548,1194.185059 1694.968384,1194.159790 1682.350464,1194.159790 
	C1682.200317,1193.617798 1682.050293,1193.075928 1681.900146,1192.533936 
	C1683.021484,1192.014526 1684.100342,1191.190186 1685.271240,1191.026855 
	C1691.269775,1190.190308 1693.910645,1188.121338 1693.984497,1182.160767 
	C1694.203369,1164.511841 1694.154175,1146.854736 1693.858887,1129.206665 
	C1693.759644,1123.278687 1691.769043,1122.160767 1685.790649,1122.737793 
	C1684.300781,1122.881592 1682.754395,1122.439575 1681.234009,1122.266846 
	C1681.223389,1121.617432 1681.212646,1120.968018 1681.202026,1120.318604 
	C1690.155151,1116.809814 1699.108154,1113.300903 1709.042969,1109.407349 
	C1709.042969,1116.022583 1709.042969,1121.082031 1709.042969,1126.141479 
	C1709.629272,1126.546387 1710.215698,1126.951172 1710.802002,1127.356079 
	C1711.500732,1126.078491 1711.908569,1124.452393 1712.948853,1123.583862 
	C1717.271362,1119.974365 1721.395752,1115.853882 1726.303711,1113.271851 
	C1739.439209,1106.361084 1752.692505,1112.694580 1755.818604,1127.204468 
	C1756.964111,1132.521118 1757.147583,1138.102417 1757.254761,1143.573120 
	C1757.467041,1154.400146 1757.165894,1165.237427 1757.394043,1176.063843 
	C1757.493408,1180.777954 1756.927612,1185.879761 1761.446289,1189.761719 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M1131.533691,1184.895264 
	C1142.314819,1187.520874 1149.378540,1181.715576 1156.009888,1175.130249 
	C1156.874634,1174.271484 1157.002808,1172.396973 1157.009521,1170.990601 
	C1157.076782,1156.670898 1157.096558,1142.350464 1157.027710,1128.030762 
	C1156.987305,1119.612061 1155.614746,1118.154541 1147.313599,1116.472534 
	C1146.074463,1116.221436 1144.988403,1115.214600 1143.831421,1114.557373 
	C1143.991211,1114.156860 1144.150879,1113.756226 1144.310669,1113.355713 
	C1153.310181,1113.355713 1162.309570,1113.355713 1171.842041,1113.355713 
	C1171.842041,1118.361572 1171.828613,1123.149536 1171.844482,1127.937622 
	C1171.892822,1142.423218 1171.935913,1156.908936 1172.026001,1171.394287 
	C1172.039429,1173.553223 1172.172119,1175.727295 1172.462036,1177.865601 
	C1173.420410,1184.934204 1174.406372,1185.587524 1181.560303,1184.542236 
	C1182.556274,1184.396729 1183.633301,1184.806885 1184.672852,1184.959839 
	C1184.359497,1185.751343 1184.248413,1187.036499 1183.702393,1187.260132 
	C1175.722412,1190.529419 1167.690796,1193.673462 1159.645752,1196.780884 
	C1159.102417,1196.990723 1158.353394,1196.668335 1157.234375,1196.540161 
	C1157.234375,1191.411377 1157.234375,1186.311890 1157.234375,1179.927612 
	C1153.180908,1183.765625 1150.048340,1186.810059 1146.829102,1189.760254 
	C1142.694580,1193.549316 1138.102173,1196.204834 1132.255615,1196.649292 
	C1121.468140,1197.469238 1113.841553,1192.923462 1111.270630,1182.477173 
	C1109.660278,1175.933472 1109.140503,1169.027466 1108.916748,1162.257935 
	C1108.548462,1151.113770 1108.845581,1139.948486 1108.807983,1128.792236 
	C1108.777222,1119.645386 1107.216309,1117.909546 1098.282593,1116.375610 
	C1097.042725,1116.162720 1095.921997,1115.255005 1094.746216,1114.668945 
	C1094.888184,1114.243408 1095.030273,1113.817871 1095.172241,1113.392334 
	C1104.364258,1113.392334 1113.556152,1113.392334 1123.515625,1113.392334 
	C1123.515625,1115.232178 1123.505493,1117.161011 1123.517212,1119.089478 
	C1123.623291,1136.569336 1123.629883,1154.050903 1123.902100,1171.528198 
	C1123.987915,1177.037354 1125.573608,1182.102051 1131.533691,1184.895264 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1419.216309,1123.435303 
	C1423.629395,1116.817261 1429.665894,1113.310181 1436.957886,1111.795898 
	C1445.431641,1110.036011 1453.866089,1110.243774 1462.029785,1113.184570 
	C1468.283447,1115.437256 1472.628174,1119.958740 1473.039795,1126.724731 
	C1473.715820,1137.838623 1473.720337,1148.994385 1473.951294,1160.133789 
	C1474.072021,1165.958008 1473.846802,1171.800049 1474.239868,1177.602783 
	C1474.394043,1179.880493 1475.810303,1182.072754 1476.651733,1184.303955 
	C1479.006104,1183.410889 1481.438965,1182.676270 1483.684204,1181.562988 
	C1484.938354,1180.941040 1485.880981,1179.690674 1486.994019,1178.696899 
	C1487.376953,1179.177490 1487.720703,1179.408936 1487.713013,1179.628052 
	C1487.427246,1187.807861 1474.010986,1197.608643 1466.239258,1195.338379 
	C1462.201416,1194.158936 1461.169922,1192.560303 1458.691162,1183.163208 
	C1456.050415,1185.125610 1453.641357,1186.960938 1451.183350,1188.728516 
	C1449.158447,1190.184570 1447.170044,1191.728882 1445.002319,1192.942993 
	C1435.493408,1198.268311 1424.229370,1196.432129 1418.334961,1188.705322 
	C1412.110474,1180.545776 1412.426147,1167.298584 1420.000854,1160.279785 
	C1424.532104,1156.081055 1430.215088,1152.920166 1435.791504,1150.108032 
	C1442.301758,1146.825073 1449.324341,1144.572021 1455.971313,1141.536133 
	C1457.368286,1140.898193 1458.867798,1138.856445 1458.978027,1137.362549 
	C1459.219482,1134.091675 1458.965454,1130.677612 1458.264282,1127.463745 
	C1456.173828,1117.882690 1447.959473,1113.546875 1438.848511,1117.135376 
	C1434.475220,1118.857788 1431.741455,1121.971436 1432.008301,1127.052368 
	C1432.095459,1128.712158 1432.135742,1130.387451 1432.015991,1132.042480 
	C1431.675781,1136.736206 1429.116211,1139.358643 1424.916992,1139.451538 
	C1420.834106,1139.541992 1417.521729,1136.862915 1417.372803,1132.438110 
	C1417.276123,1129.568848 1418.436890,1126.657471 1419.216309,1123.435303 
M1443.443970,1152.916382 
	C1440.963501,1154.237305 1438.332642,1155.339478 1436.032715,1156.922607 
	C1430.527222,1160.712036 1427.815308,1168.070435 1429.290771,1174.457642 
	C1430.621216,1180.217407 1434.763916,1184.557251 1439.665771,1185.326538 
	C1444.564331,1186.095459 1456.340088,1180.935547 1458.649170,1176.956055 
	C1458.960938,1176.418457 1459.110962,1175.710693 1459.112793,1175.081055 
	C1459.141479,1165.647217 1459.132568,1156.213501 1459.132568,1145.903564 
	C1453.702759,1148.324097 1448.913940,1150.458984 1443.443970,1152.916382 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M766.039185,1196.852539 
	C735.083923,1195.628418 720.468994,1161.576538 731.390930,1136.557129 
	C734.707214,1128.960083 739.309875,1122.276245 746.231873,1117.533936 
	C766.784973,1103.452881 793.142334,1111.888916 803.012085,1135.624390 
	C811.843628,1156.863037 803.099670,1182.101562 782.696655,1192.566772 
	C777.838501,1195.058716 771.917908,1195.479370 766.039185,1196.852539 
M750.878906,1176.497925 
	C752.850281,1179.157227 754.538696,1182.096069 756.841858,1184.427734 
	C766.963379,1194.674927 782.400818,1191.466309 786.716736,1177.732056 
	C791.948547,1161.083008 790.821411,1144.453369 782.726685,1128.823486 
	C779.086670,1121.795166 773.415344,1116.589722 764.687866,1116.567139 
	C755.385986,1116.543091 748.455994,1122.540405 746.009521,1133.483643 
	C742.757324,1148.031616 744.172546,1162.232056 750.878906,1176.497925 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1653.690430,1190.723267 
	C1639.965210,1198.792358 1626.462402,1199.281738 1613.439575,1190.421753 
	C1600.039185,1181.304810 1594.632202,1167.861816 1595.315796,1152.084839 
	C1595.922974,1138.073730 1601.732300,1126.189209 1613.194214,1117.879028 
	C1631.175903,1104.842163 1655.107300,1109.983276 1667.001587,1128.984131 
	C1679.672852,1149.226196 1673.806396,1177.010376 1653.690430,1190.723267 
M1619.979736,1179.438843 
	C1620.544067,1180.262329 1621.058472,1181.125610 1621.680664,1181.902832 
	C1626.598633,1188.045776 1632.688965,1191.521240 1640.817505,1189.961304 
	C1648.861206,1188.417725 1653.011353,1182.806763 1654.885620,1175.429077 
	C1658.826782,1159.916016 1657.716797,1144.696167 1650.865723,1130.156738 
	C1646.328125,1120.527100 1638.663696,1115.826904 1630.091064,1116.700073 
	C1621.846436,1117.539917 1615.406372,1123.482178 1613.307861,1133.447388 
	C1609.983398,1149.232910 1611.546021,1164.530273 1619.979736,1179.438843 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M623.277832,1188.301514 
	C608.292664,1201.103516 587.682312,1198.721924 576.138550,1183.132446 
	C562.183716,1164.286865 564.831543,1133.756958 581.721863,1118.753906 
	C590.926025,1110.578125 607.235779,1108.188965 618.298523,1113.561646 
	C630.398499,1119.438110 635.202637,1129.734619 635.842346,1143.393311 
	C617.199524,1143.393311 598.864929,1143.393311 580.653931,1143.393311 
	C577.918091,1162.100708 591.498413,1181.061279 607.618286,1182.097168 
	C617.883240,1182.756836 625.273071,1178.506592 630.044128,1169.505005 
	C631.123962,1167.467651 632.131958,1165.392090 633.173035,1163.334229 
	C633.639404,1163.316162 634.105713,1163.298096 634.572083,1163.280029 
	C634.767578,1164.704102 635.370117,1166.213501 635.091919,1167.538086 
	C633.405945,1175.564697 629.557007,1182.436768 623.277832,1188.301514 
M594.510620,1138.536743 
	C601.920593,1138.536743 609.330505,1138.536743 617.197327,1138.536743 
	C616.695923,1130.251221 615.185120,1123.218628 608.088135,1119.039307 
	C602.098511,1115.512207 595.827332,1116.124146 590.160950,1119.803711 
	C583.656372,1124.027588 580.596558,1130.361206 580.044006,1138.538574 
	C584.914856,1138.538574 589.216064,1138.538574 594.510620,1138.536743 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M466.858276,1179.424561 
	C483.473267,1185.822388 491.959656,1181.944092 503.022278,1162.057861 
	C503.625519,1164.641357 504.354095,1165.996948 504.161041,1167.205322 
	C502.041260,1180.473755 490.205231,1193.484009 478.143677,1195.995361 
	C464.678680,1198.798706 451.667694,1193.085815 443.405579,1180.742188 
	C431.351044,1162.732666 434.667877,1132.723511 450.756805,1118.843018 
	C461.409729,1109.652344 473.969208,1108.350464 486.797302,1113.429688 
	C498.253967,1117.966064 504.698181,1129.342285 504.509460,1143.380615 
	C486.192596,1143.380615 467.848969,1143.380615 449.629700,1143.380615 
	C447.474854,1157.076172 454.671295,1171.921631 466.858276,1179.424561 
M456.943695,1121.373047 
	C451.945831,1125.869629 449.445618,1131.438721 448.954254,1138.156860 
	C461.734009,1138.156860 473.745758,1138.156860 486.093567,1138.156860 
	C485.796753,1130.145264 484.166992,1123.097412 477.051331,1119.030518 
	C470.315887,1115.180908 463.652954,1116.412720 456.943695,1121.373047 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M706.786560,1183.737549 
	C689.612183,1204.876831 661.927246,1198.479248 651.731750,1175.306274 
	C642.947449,1155.341064 647.337952,1132.189087 662.702637,1119.102661 
	C674.449097,1109.098022 692.474976,1107.825928 704.475281,1116.278442 
	C708.768005,1119.302124 712.397949,1122.967041 713.130005,1128.533691 
	C713.782959,1133.498169 711.834656,1136.365723 707.302124,1137.406860 
	C702.401062,1138.532593 697.611450,1136.327393 696.179810,1132.220703 
	C695.798767,1131.127441 695.611877,1129.962891 695.379089,1128.822266 
	C692.898804,1116.667480 682.451965,1112.571655 672.451416,1120.184448 
	C669.521545,1122.414795 666.655334,1125.570312 665.329285,1128.922607 
	C659.018738,1144.876587 660.164917,1160.408813 670.917908,1174.128906 
	C680.732727,1186.651855 697.876038,1185.769287 707.098816,1172.822266 
	C709.399414,1169.592529 711.254028,1166.045166 713.322998,1162.628296 
	C716.467896,1165.377808 714.183044,1172.775757 706.786560,1183.737549 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1375.496216,1118.107544 
	C1365.697144,1115.005859 1357.528809,1118.089966 1352.440918,1126.499268 
	C1344.017456,1140.420776 1346.846191,1164.357788 1358.284180,1175.945190 
	C1367.960449,1185.747925 1382.999512,1185.199585 1391.675415,1174.463379 
	C1394.642090,1170.792114 1396.891357,1166.541138 1399.823608,1162.005737 
	C1400.443359,1163.263550 1401.247192,1164.099243 1401.130127,1164.775513 
	C1399.039062,1176.852417 1390.097778,1192.952637 1373.313232,1196.047363 
	C1359.526489,1198.589355 1348.964722,1192.856445 1341.195435,1181.638062 
	C1327.894531,1162.432007 1331.250977,1133.985840 1348.441895,1119.259155 
	C1360.121704,1109.253662 1377.603149,1107.621704 1389.732544,1116.035034 
	C1393.252563,1118.476440 1396.281372,1122.235718 1398.326782,1126.040649 
	C1400.948364,1130.917480 1398.780762,1135.624268 1394.342529,1137.142822 
	C1388.854126,1139.020508 1383.482422,1136.561768 1381.901489,1131.354492 
	C1381.421021,1129.771729 1381.576172,1127.927124 1380.851929,1126.501465 
	C1379.404297,1123.652466 1377.549927,1121.010010 1375.496216,1118.107544 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M517.378784,1191.499023 
	C526.895325,1190.325195 528.856201,1188.355225 528.880249,1178.971191 
	C528.952759,1150.673462 528.913269,1122.375244 528.877319,1094.077271 
	C528.874573,1091.921997 528.650391,1089.754761 528.368713,1087.614258 
	C527.325256,1079.685181 527.127808,1079.585815 518.896362,1080.523193 
	C518.077515,1080.616455 517.190491,1080.111572 516.335144,1079.884155 
	C516.592590,1079.053101 516.629883,1077.679321 517.140564,1077.472046 
	C525.126648,1074.231079 533.167358,1071.123779 541.221558,1068.054077 
	C541.755920,1067.850464 542.500061,1068.197266 543.698425,1068.365601 
	C543.698425,1070.365112 543.696289,1072.321899 543.698730,1074.278564 
	C543.742065,1109.067383 543.737183,1143.856445 543.860901,1178.645020 
	C543.895813,1188.458618 544.898193,1189.369751 554.575195,1191.054077 
	C555.479309,1191.211426 556.246887,1192.153198 557.077881,1192.730835 
	C556.917969,1193.220093 556.758057,1193.709351 556.598206,1194.198608 
	C551.824463,1194.198608 547.050720,1194.199341 542.276978,1194.198486 
	C535.119263,1194.197388 527.958008,1194.308350 520.807068,1194.087036 
	C519.547180,1194.047974 518.330322,1192.617188 517.378784,1191.499023 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M710.149536,1380.550049 
	C701.369080,1382.127197 693.619995,1380.445190 686.175659,1376.413696 
	C683.023743,1374.706665 681.765076,1372.739990 681.805420,1369.038452 
	C682.010315,1350.225220 681.900452,1331.408447 681.900574,1312.593018 
	C681.900574,1310.761475 681.913086,1308.929688 681.896118,1307.098267 
	C681.801147,1296.863159 681.798645,1296.863159 672.102478,1294.032959 
	C678.665649,1291.392212 685.228882,1288.751343 692.690918,1285.748779 
	C692.690918,1300.834473 692.690918,1314.430054 692.690918,1328.025757 
	C693.224792,1328.431396 693.758667,1328.837158 694.292480,1329.242798 
	C695.483948,1327.585205 696.454468,1325.697632 697.902588,1324.307495 
	C707.576599,1315.020508 720.711060,1316.417603 728.449524,1327.412476 
	C740.067871,1343.919800 734.689026,1373.311157 710.149536,1380.550049 
M693.388794,1370.400146 
	C705.096497,1380.659546 716.521912,1377.592285 721.277649,1362.806763 
	C723.904968,1354.638672 723.921326,1346.403809 720.594360,1338.415527 
	C718.160339,1332.571411 714.046570,1328.407104 707.435547,1327.496460 
	C701.015869,1326.611938 692.914429,1331.966797 692.888428,1337.221191 
	C692.834961,1348.027710 692.963074,1358.835205 693.388794,1370.400146 
z"/>
<path fill="#F8FDF8" opacity="1.000000" stroke="none" 
	d="
M1536.467041,1183.484619 
	C1532.799438,1189.873169 1527.947510,1194.186157 1520.811646,1195.253418 
	C1512.364990,1196.516724 1505.932251,1192.309937 1504.049561,1184.010254 
	C1503.212158,1180.318604 1502.993530,1176.426514 1502.965820,1172.621948 
	C1502.850586,1156.792603 1502.914917,1140.962036 1502.913452,1125.131836 
	C1502.913330,1123.346313 1502.913452,1121.560791 1502.913452,1119.141724 
	C1498.330688,1119.141724 1494.278320,1119.141724 1488.781128,1119.141724 
	C1494.923218,1113.487427 1500.608643,1109.348755 1504.843018,1104.050537 
	C1509.166504,1098.640747 1512.065430,1092.092529 1515.588013,1086.042603 
	C1516.026489,1086.127808 1516.464844,1086.212891 1516.903320,1086.297974 
	C1516.903320,1094.939331 1516.903320,1103.580688 1516.903320,1112.877686 
	C1523.741943,1112.877686 1529.785156,1112.877686 1536.052979,1112.877686 
	C1536.052979,1115.163818 1536.052979,1116.775146 1536.052979,1118.933594 
	C1530.101562,1118.933594 1524.174316,1118.933594 1518.021118,1118.933594 
	C1517.731812,1120.509644 1517.359375,1121.601196 1517.356812,1122.693604 
	C1517.318848,1138.690186 1517.320801,1154.687012 1517.344116,1170.683716 
	C1517.346436,1172.345825 1517.500000,1174.012207 1517.661743,1175.668945 
	C1518.047852,1179.625244 1518.878052,1183.566040 1523.297607,1184.767212 
	C1527.790405,1185.988159 1531.348999,1184.061890 1534.145752,1180.391968 
	C1535.020264,1179.244385 1536.488037,1178.548828 1537.684204,1177.646362 
	C1537.996338,1177.986450 1538.308594,1178.326416 1538.620728,1178.666382 
	C1537.966675,1180.172119 1537.312744,1181.677734 1536.467041,1183.484619 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1718.027100,1372.239990 
	C1718.706177,1372.901001 1719.013184,1373.642212 1719.320068,1374.383423 
	C1713.128906,1376.893311 1706.937622,1379.403198 1699.665894,1382.351074 
	C1699.665894,1377.403198 1699.665894,1374.028198 1699.665894,1369.336060 
	C1696.537231,1372.218994 1694.388672,1374.174561 1692.267090,1376.158936 
	C1688.297974,1379.870728 1683.730957,1381.648315 1678.188843,1381.228516 
	C1672.832886,1380.822998 1668.630981,1378.562744 1667.069336,1373.494141 
	C1665.525635,1368.484009 1664.539307,1363.166626 1664.259277,1357.933228 
	C1663.806396,1349.468140 1664.170898,1340.961060 1664.116943,1332.472290 
	C1664.066284,1324.505249 1663.656860,1324.061401 1655.822632,1322.155762 
	C1655.247925,1322.015991 1654.734985,1321.621948 1654.272095,1320.447388 
	C1660.982788,1320.447388 1667.693481,1320.447388 1675.018677,1320.447388 
	C1675.018677,1329.397217 1675.000488,1338.500488 1675.025757,1347.603760 
	C1675.040161,1352.762695 1674.804565,1357.949219 1675.249878,1363.073975 
	C1676.047119,1372.249023 1682.525146,1375.785278 1690.831055,1371.668945 
	C1696.426392,1368.895874 1700.452881,1365.327148 1699.799683,1357.859741 
	C1699.062866,1349.435059 1699.649780,1340.896118 1699.623169,1332.407104 
	C1699.599854,1324.960693 1699.008301,1324.237427 1691.747803,1322.176025 
	C1691.187866,1322.017090 1690.700928,1321.600830 1690.309082,1320.422485 
	C1696.858521,1320.422485 1703.407959,1320.422485 1710.558105,1320.422485 
	C1710.558105,1325.817383 1710.510132,1331.276245 1710.568481,1336.733887 
	C1710.676758,1346.883667 1710.835815,1357.032837 1710.996948,1367.182007 
	C1711.067749,1371.643433 1713.004028,1373.758911 1718.027100,1372.239990 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M661.855103,1340.064331 
	C661.887939,1348.878906 661.805847,1357.207153 662.031860,1365.526855 
	C662.091064,1367.704102 663.107544,1369.855347 663.684387,1372.018555 
	C665.804321,1371.045654 667.926147,1370.076904 670.042542,1369.096436 
	C670.488525,1368.889893 670.915039,1368.641357 671.374451,1368.399658 
	C671.603027,1373.103760 665.422302,1379.391846 659.637634,1380.407227 
	C654.807800,1381.254883 652.526123,1379.169678 650.489807,1371.608521 
	C647.673096,1373.606201 645.045654,1375.538086 642.349670,1377.369141 
	C634.599365,1382.632690 625.752991,1381.779175 620.865295,1375.341064 
	C615.859558,1368.747437 616.690247,1357.996948 623.873779,1353.030396 
	C630.346375,1348.555542 637.992371,1345.789795 645.072144,1342.174683 
	C647.463745,1340.953491 651.389221,1341.485718 651.015442,1336.900269 
	C650.593201,1331.720825 650.759827,1326.207275 645.370483,1323.353516 
	C637.991455,1319.446289 631.459290,1323.716675 630.916687,1332.084717 
	C630.767151,1334.390991 629.370972,1337.046387 627.692871,1338.645142 
	C626.832703,1339.464722 623.028870,1338.938843 622.414246,1337.910400 
	C621.115295,1335.736694 620.138855,1332.646606 620.632507,1330.264526 
	C621.978210,1323.770264 629.564270,1318.898438 638.491272,1318.217529 
	C641.288940,1318.004150 644.162964,1318.122192 646.945557,1318.502075 
	C656.931152,1319.865356 660.915588,1324.132080 661.724121,1334.084106 
	C661.872070,1335.905884 661.816711,1337.744141 661.855103,1340.064331 
M650.996033,1357.445679 
	C650.996033,1353.213745 650.996033,1348.981689 650.996033,1343.745972 
	C644.799316,1346.744751 639.306030,1349.030151 634.239685,1352.035400 
	C632.164551,1353.266235 630.395508,1355.834717 629.569824,1358.176392 
	C627.929260,1362.829224 628.687378,1367.380615 632.572632,1370.900391 
	C636.258118,1374.239014 640.052063,1372.978271 644.136841,1371.383057 
	C650.370422,1368.948853 651.951904,1364.444946 650.996033,1357.445679 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1392.988525,1323.943604 
	C1404.475586,1316.461182 1415.483643,1316.265381 1425.461548,1323.154785 
	C1435.378052,1330.001587 1440.205566,1343.532837 1437.278442,1356.480103 
	C1434.568848,1368.464966 1427.819336,1377.020874 1415.685059,1380.280640 
	C1405.335815,1383.061035 1396.244873,1380.252075 1388.867920,1372.438354 
	C1378.949463,1361.932495 1377.830322,1343.452148 1386.423706,1331.228882 
	C1388.224487,1328.667358 1390.593750,1326.505493 1392.988525,1323.943604 
M1417.518799,1326.915283 
	C1409.758301,1320.324951 1400.832397,1321.259766 1396.280518,1329.139526 
	C1389.942017,1340.112183 1392.504639,1362.452393 1401.151855,1371.606934 
	C1409.045044,1379.963257 1420.680542,1377.803833 1423.954468,1366.835205 
	C1428.092163,1352.973022 1426.842163,1339.531006 1417.518799,1326.915283 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1641.030762,1326.971680 
	C1651.775635,1340.426758 1652.068359,1357.897705 1641.998169,1370.482300 
	C1628.748535,1387.039917 1604.105713,1384.079224 1595.097656,1364.847412 
	C1585.796143,1344.989136 1597.310913,1321.198120 1617.690186,1318.397949 
	C1626.744995,1317.154053 1634.337524,1320.465698 1641.030762,1326.971680 
M1610.376709,1369.052002 
	C1618.275024,1379.218262 1630.710205,1379.187622 1634.603027,1368.491455 
	C1639.233032,1355.769287 1638.360718,1342.870972 1631.733521,1330.877808 
	C1627.985718,1324.095459 1622.339600,1321.554321 1616.001709,1322.839233 
	C1609.080200,1324.242310 1606.313354,1329.537231 1605.050049,1335.651733 
	C1602.680786,1347.119995 1604.066528,1358.148926 1610.376709,1369.052002 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1552.993896,1377.881104 
	C1547.262329,1365.002930 1541.473999,1352.501953 1535.657471,1340.014160 
	C1534.324341,1337.151733 1533.269043,1334.057251 1531.422119,1331.560547 
	C1528.573608,1327.710083 1525.152466,1324.283203 1521.747314,1320.419189 
	C1530.599487,1320.419189 1539.698242,1320.419189 1548.929810,1320.419189 
	C1548.730957,1321.199829 1548.730469,1321.727783 1548.561890,1321.788330 
	C1541.363037,1324.375122 1540.604614,1325.672363 1543.732666,1332.550903 
	C1548.334595,1342.670288 1553.267212,1352.639160 1558.540283,1363.680054 
	C1561.224609,1357.194824 1563.542725,1351.622803 1565.837402,1346.041260 
	C1567.543823,1341.890991 1569.246948,1337.738892 1570.903198,1333.568481 
	C1574.093018,1325.536621 1574.075806,1325.520874 1566.259644,1320.400146 
	C1573.490479,1320.400146 1579.811523,1320.400146 1587.514282,1320.400146 
	C1576.088501,1326.751221 1575.576294,1338.256348 1571.312622,1347.522949 
	C1565.205078,1360.797485 1560.390869,1374.661987 1554.501709,1388.044678 
	C1552.200928,1393.272827 1548.956055,1398.274902 1545.275391,1402.652100 
	C1541.767578,1406.824219 1536.738037,1409.015381 1530.940430,1407.930786 
	C1526.619263,1407.122559 1524.014526,1403.599976 1524.732178,1399.734741 
	C1525.397339,1396.152344 1528.942993,1394.372314 1533.401001,1395.383057 
	C1543.673828,1397.712036 1545.057495,1396.992432 1549.306763,1387.016846 
	C1550.544189,1384.111816 1551.722046,1381.181641 1552.993896,1377.881104 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1572.205078,1142.000000 
	C1572.311401,1154.979614 1572.166382,1167.468140 1572.608521,1179.935791 
	C1572.926147,1188.892822 1573.754028,1189.377563 1582.470337,1191.046753 
	C1583.237671,1191.193726 1583.875610,1192.017334 1584.573608,1192.526611 
	C1583.894897,1193.071411 1583.219971,1194.085693 1582.536865,1194.091187 
	C1570.720703,1194.187622 1558.903076,1194.186523 1547.086914,1194.087158 
	C1546.445190,1194.081787 1545.812500,1193.028198 1545.175537,1192.462646 
	C1545.885986,1191.972900 1546.537720,1191.196289 1547.315674,1191.036865 
	C1556.293213,1189.198608 1557.253174,1188.518677 1557.328857,1179.390015 
	C1557.464111,1163.085205 1557.287598,1146.773682 1556.951538,1130.470947 
	C1556.803223,1123.274414 1555.159058,1122.234619 1547.957153,1122.883789 
	C1546.730347,1122.994385 1545.437622,1122.371826 1544.175293,1122.087891 
	C1544.160645,1121.520142 1544.145996,1120.952271 1544.131226,1120.384521 
	C1553.083252,1116.849121 1562.035278,1113.313721 1572.205200,1109.297363 
	C1572.205200,1121.018311 1572.205200,1131.259155 1572.205078,1142.000000 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1297.212158,1114.292969 
	C1301.397583,1112.739624 1305.230835,1111.342529 1310.193237,1109.533813 
	C1310.193237,1112.683350 1310.189209,1114.598145 1310.193848,1116.513062 
	C1310.243164,1137.004150 1310.283569,1157.495361 1310.363159,1177.986328 
	C1310.369629,1179.641724 1310.570068,1181.307495 1310.811768,1182.948853 
	C1311.722290,1189.131958 1313.142700,1190.360962 1319.465332,1191.034790 
	C1320.544678,1191.149780 1321.545410,1192.002686 1322.582397,1192.514893 
	C1321.575195,1193.073853 1320.572754,1194.108521 1319.559814,1194.118896 
	C1308.398438,1194.233398 1297.234985,1194.231812 1286.073730,1194.111816 
	C1285.101807,1194.101440 1284.141357,1193.027710 1283.175537,1192.447754 
	C1284.056641,1191.958374 1284.891357,1191.172852 1285.826172,1191.026123 
	C1293.432861,1189.834106 1295.067627,1188.597900 1295.159790,1180.805786 
	C1295.360474,1163.822266 1295.196045,1146.830444 1294.899658,1129.846924 
	C1294.787598,1123.429688 1292.834595,1122.214233 1286.303467,1122.815308 
	C1285.088379,1122.927124 1283.797974,1122.219604 1282.542236,1121.890137 
	C1283.456787,1120.963501 1284.208618,1119.679688 1285.315674,1119.175171 
	C1289.096313,1117.452148 1293.002075,1116.003174 1297.212158,1114.292969 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1347.613525,1371.033203 
	C1347.758057,1355.526367 1347.758057,1340.423218 1347.758057,1325.554443 
	C1344.903809,1322.182251 1337.823242,1328.163818 1336.553345,1319.837524 
	C1340.653687,1319.837524 1344.069336,1319.837524 1347.777832,1319.837524 
	C1348.201904,1315.901855 1348.444458,1312.452271 1348.962280,1309.044434 
	C1351.649414,1291.354248 1368.353394,1282.032715 1383.219360,1289.893677 
	C1384.241211,1290.433960 1385.254761,1291.052612 1386.126465,1291.803833 
	C1389.799194,1294.968872 1390.442383,1299.170776 1387.724487,1301.759277 
	C1385.022217,1304.332886 1381.698975,1303.593506 1378.427734,1299.675049 
	C1377.895264,1299.037231 1377.398682,1298.367310 1376.916016,1297.690430 
	C1374.160767,1293.827637 1370.896118,1290.694092 1365.692261,1292.190308 
	C1360.337769,1293.729980 1359.688232,1298.447388 1359.487915,1303.013794 
	C1359.255493,1308.314453 1359.433838,1313.633057 1359.433838,1319.799805 
	C1363.124756,1319.799805 1366.892212,1319.588989 1370.613647,1319.911011 
	C1371.967041,1320.027954 1373.210327,1321.415649 1374.503906,1322.223511 
	C1373.148682,1322.998169 1371.842041,1324.314819 1370.428833,1324.441162 
	C1366.849976,1324.761475 1363.223267,1324.547607 1359.497559,1324.547607 
	C1359.497559,1340.000000 1359.190674,1354.771729 1359.645630,1369.520020 
	C1359.832886,1375.590698 1362.088867,1376.828613 1368.492188,1377.143188 
	C1369.855103,1377.210205 1371.210205,1377.435425 1372.568970,1377.587769 
	C1372.542603,1378.016357 1372.516235,1378.444946 1372.489868,1378.873535 
	C1360.773438,1378.873535 1349.056885,1378.873535 1337.340454,1378.873535 
	C1341.058105,1376.515259 1346.475220,1377.243774 1347.613525,1371.033203 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1194.063477,1362.774414 
	C1190.202026,1348.108276 1191.320190,1334.672119 1202.784302,1324.204956 
	C1210.580933,1317.086304 1220.258179,1316.071533 1229.857422,1320.413574 
	C1238.646606,1324.389160 1242.080811,1332.059570 1242.415894,1342.146484 
	C1228.756104,1342.146484 1215.382324,1342.146484 1202.119019,1342.146484 
	C1200.719482,1353.165283 1206.576904,1364.975464 1215.056152,1368.800049 
	C1220.296753,1371.163940 1226.057495,1372.101929 1230.833984,1368.685303 
	C1234.930298,1365.755127 1237.947388,1361.316040 1241.677490,1357.289673 
	C1241.781128,1358.196777 1242.136475,1359.246216 1241.974365,1360.208740 
	C1240.387207,1369.635620 1231.169800,1379.390381 1222.485474,1380.943237 
	C1211.903687,1382.835693 1201.614624,1377.656372 1196.245728,1367.688232 
	C1195.459106,1366.227783 1194.854370,1364.669067 1194.063477,1362.774414 
M1219.736450,1323.172607 
	C1209.058960,1321.987061 1201.789307,1328.073975 1202.158325,1338.077881 
	C1210.961060,1338.077881 1219.765137,1338.077881 1228.815552,1338.077881 
	C1228.295654,1331.507324 1226.730347,1326.149902 1219.736450,1323.172607 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M802.341309,1367.915039 
	C815.770386,1374.448608 824.131592,1368.859375 830.140503,1355.582886 
	C830.699829,1357.822266 831.158020,1358.595215 831.011047,1359.227539 
	C828.724060,1369.062500 823.612854,1376.853394 813.698608,1380.109741 
	C804.730957,1383.055176 796.578796,1380.742188 789.863281,1374.066895 
	C775.596985,1359.885742 778.977844,1330.257080 795.843201,1321.152954 
	C811.765991,1312.557983 830.780457,1322.557495 831.139099,1339.723755 
	C831.152222,1340.355225 831.001038,1340.990112 830.862671,1342.164307 
	C817.512939,1342.164307 804.257019,1342.164307 790.346863,1342.164307 
	C790.563110,1352.869995 793.347290,1361.592285 802.341309,1367.915039 
M795.043762,1338.552490 
	C802.458435,1338.552490 809.873047,1338.552490 817.261047,1338.552490 
	C817.299255,1329.154053 813.322266,1323.551025 806.673401,1322.868286 
	C799.320496,1322.113281 792.826172,1327.010376 790.903503,1334.508789 
	C790.104492,1337.625122 790.929688,1338.952148 795.043762,1338.552490 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M927.892334,1363.978516 
	C923.861145,1350.835327 924.432983,1338.596558 932.752808,1327.594849 
	C939.227051,1319.033936 951.376160,1315.843750 961.558411,1319.618652 
	C970.422791,1322.905029 976.023621,1331.712402 975.450745,1342.168213 
	C962.119690,1342.168213 948.733032,1342.168213 935.442688,1342.168213 
	C934.078308,1353.444946 940.144043,1365.316040 948.816528,1368.985596 
	C953.950623,1371.157837 959.546021,1372.018555 964.135193,1368.725708 
	C968.251892,1365.771973 971.308167,1361.340332 975.067810,1357.307983 
	C975.144714,1358.026367 975.477905,1359.028442 975.315186,1359.942383 
	C973.491638,1370.186768 964.254272,1379.783813 955.130615,1381.010254 
	C943.199341,1382.614380 933.972656,1376.938354 927.892334,1363.978516 
M940.771484,1338.553101 
	C947.721008,1338.553101 954.670593,1338.553101 961.554504,1338.553101 
	C962.487793,1329.491089 957.509583,1323.022461 949.917419,1322.825684 
	C941.536743,1322.608643 934.916077,1329.588989 935.473145,1338.333130 
	C936.909729,1338.404663 938.379761,1338.477905 940.771484,1338.553101 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M530.352783,1362.251221 
	C531.438782,1360.259033 532.328369,1358.570679 533.217896,1356.882202 
	C533.679199,1356.993652 534.140503,1357.105103 534.601868,1357.216553 
	C534.525024,1358.497314 534.652100,1359.830200 534.339417,1361.050537 
	C531.625427,1371.641724 522.819336,1380.143921 513.736084,1381.049316 
	C502.194366,1382.199707 493.245422,1376.851929 487.845947,1365.577515 
	C481.417206,1352.153931 484.680267,1333.039795 495.351074,1324.142944 
	C503.463043,1317.379517 512.857666,1316.021240 522.458069,1320.443115 
	C531.196289,1324.467773 534.643982,1332.132812 535.068481,1342.094360 
	C521.367310,1342.094360 507.969208,1342.094360 494.671387,1342.094360 
	C493.094055,1353.258545 499.452362,1365.418823 508.376495,1369.081787 
	C517.090393,1372.658325 524.447510,1370.453613 530.352783,1362.251221 
M513.376282,1338.551147 
	C515.928711,1338.551147 518.481201,1338.551147 520.991394,1338.551147 
	C520.996704,1328.303711 516.433655,1322.839600 508.674133,1322.823975 
	C500.640930,1322.807739 494.553894,1329.385254 494.596466,1338.549927 
	C500.524689,1338.549927 506.466980,1338.549927 513.376282,1338.551147 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M901.517090,1375.911255 
	C907.633606,1370.594727 907.772766,1364.292847 901.461487,1359.862427 
	C895.639404,1355.774902 889.040405,1352.813232 883.016296,1348.989136 
	C875.675110,1344.329224 873.425293,1337.884766 875.883972,1330.112183 
	C878.462280,1321.961426 886.757812,1316.934448 895.489624,1318.267456 
	C897.132507,1318.518188 898.744690,1319.119507 900.387268,1319.192017 
	C903.724670,1319.339355 907.073242,1319.236084 910.827026,1319.236084 
	C910.827026,1324.802368 910.827026,1331.373413 910.827026,1337.944458 
	C910.422058,1338.060547 910.017029,1338.176636 909.612061,1338.292725 
	C908.716125,1336.135620 907.846863,1333.966797 906.919556,1331.823242 
	C903.646179,1324.257202 897.848389,1320.477905 890.865601,1322.486816 
	C887.944641,1323.327271 884.178162,1326.038940 883.522461,1328.598389 
	C882.858276,1331.191040 884.895203,1335.243042 886.971313,1337.561035 
	C889.181519,1340.028931 892.791199,1341.275391 895.851318,1342.942017 
	C899.497803,1344.928223 903.378113,1346.531738 906.858887,1348.765747 
	C914.511536,1353.677490 917.175903,1360.166504 915.117737,1367.825439 
	C913.156555,1375.123413 905.802490,1380.897095 897.638672,1381.105835 
	C894.019348,1381.198486 890.379395,1380.542969 886.750854,1380.204956 
	C883.251709,1379.879028 879.755127,1379.524780 875.763062,1379.134888 
	C875.763062,1372.954102 875.763062,1366.077515 875.763062,1359.201050 
	C881.167664,1369.856567 886.011841,1380.564453 901.517090,1375.911255 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1160.625977,1381.050293 
	C1148.721069,1381.990112 1141.079834,1376.266113 1136.229980,1366.569824 
	C1127.927002,1349.969971 1133.456299,1329.303101 1148.204712,1321.425903 
	C1156.145752,1317.184448 1164.548462,1316.397949 1172.501099,1321.139648 
	C1175.612915,1322.995117 1178.304443,1326.108154 1180.235718,1329.235962 
	C1182.172241,1332.372314 1181.400146,1335.798096 1177.524048,1337.502319 
	C1173.842896,1339.120850 1169.350830,1337.071289 1168.185913,1333.398560 
	C1168.036377,1332.927124 1167.999390,1332.419556 1167.917236,1331.927490 
	C1167.275757,1328.079102 1166.628540,1324.131226 1162.008545,1323.049316 
	C1156.947021,1321.863770 1152.133789,1322.821655 1148.876831,1326.966675 
	C1146.690796,1329.748779 1144.953369,1333.194702 1144.053833,1336.616577 
	C1141.658813,1345.725952 1143.132812,1354.551392 1148.116821,1362.512329 
	C1155.269043,1373.936401 1169.430298,1374.048462 1177.003052,1362.915771 
	C1178.463501,1360.768555 1179.690308,1358.462280 1181.208252,1355.922852 
	C1184.109863,1365.158203 1173.430542,1378.081909 1160.625977,1381.050293 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1289.094971,1328.009766 
	C1289.094604,1331.585571 1289.094604,1334.681030 1289.094604,1337.776611 
	C1288.575195,1337.938110 1288.055664,1338.099609 1287.536255,1338.261230 
	C1286.686279,1336.375610 1285.780518,1334.512939 1284.995972,1332.600586 
	C1281.705200,1324.579224 1276.011230,1320.542603 1268.913940,1322.412231 
	C1265.953369,1323.192139 1262.520020,1325.682983 1261.207397,1328.338013 
	C1259.522217,1331.746826 1261.279907,1335.611694 1264.793823,1337.781738 
	C1268.604858,1340.134888 1272.605347,1342.183228 1276.537598,1344.337891 
	C1279.162964,1345.776367 1281.916748,1347.006592 1284.434448,1348.610596 
	C1291.925293,1353.383301 1294.740234,1359.549072 1293.097656,1367.120728 
	C1291.593384,1374.055054 1284.831299,1380.179321 1277.097534,1380.945190 
	C1273.338867,1381.317383 1269.470337,1380.816895 1265.671631,1380.483765 
	C1261.678833,1380.133545 1257.709839,1379.511475 1253.421875,1378.968994 
	C1253.421875,1372.950928 1253.421875,1366.121338 1253.421875,1359.291870 
	C1253.820557,1359.173340 1254.219238,1359.054810 1254.618042,1358.936279 
	C1255.233521,1360.419922 1255.879761,1361.891846 1256.459473,1363.389404 
	C1259.269897,1370.649902 1263.617676,1376.217285 1271.977051,1377.167236 
	C1277.490967,1377.793945 1281.941284,1375.518677 1283.377563,1371.161987 
	C1284.935181,1366.436890 1283.169800,1362.424683 1279.254517,1359.845581 
	C1274.268188,1356.560913 1268.844482,1353.948486 1263.702026,1350.890259 
	C1258.161865,1347.595703 1253.114624,1344.005249 1252.929077,1336.473999 
	C1252.651489,1325.197266 1261.662598,1316.757935 1272.858032,1318.214722 
	C1274.505371,1318.428955 1276.118042,1319.057373 1277.761719,1319.123779 
	C1281.288574,1319.266602 1284.825439,1319.167725 1289.095215,1319.167725 
	C1289.095215,1321.359131 1289.095215,1324.444458 1289.094971,1328.009766 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M549.964478,1376.028564 
	C551.146118,1373.220703 552.669250,1370.616943 552.693481,1367.999268 
	C552.885925,1347.197021 552.798523,1326.392212 552.777466,1305.588135 
	C552.776123,1304.260132 552.600891,1302.932861 552.524841,1301.604248 
	C552.276794,1297.268311 550.800354,1294.462524 545.683167,1295.871826 
	C545.041321,1296.048584 544.234436,1295.626587 543.504028,1295.481812 
	C543.378174,1294.998657 543.252319,1294.515381 543.126465,1294.032104 
	C549.588257,1291.429443 556.050110,1288.826660 563.729309,1285.733643 
	C563.729309,1289.092163 563.729370,1291.000854 563.729309,1292.909546 
	C563.728271,1316.043701 563.721985,1339.177979 563.733582,1362.312134 
	C563.734863,1364.807129 563.843628,1367.302124 563.896484,1369.797241 
	C563.990601,1374.247070 565.657104,1377.193115 570.685547,1377.087524 
	C571.417908,1377.072021 572.159058,1377.478760 572.787842,1378.771118 
	C563.267334,1378.771118 553.746887,1378.771118 544.226379,1378.771118 
	C544.151245,1378.485229 544.076111,1378.199219 544.000916,1377.913330 
	C545.871094,1377.350830 547.741333,1376.788452 549.964478,1376.028564 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M761.209961,1286.912354 
	C762.329590,1288.597534 763.625916,1290.323975 763.635742,1292.057861 
	C763.769287,1315.519287 763.720825,1338.981567 763.728333,1362.443726 
	C763.729065,1364.605957 763.856445,1366.768555 763.835632,1368.930176 
	C763.786743,1373.999268 765.518005,1377.385254 771.305969,1377.156006 
	C771.752258,1377.138306 772.223450,1377.473511 772.659912,1377.691040 
	C772.759949,1377.741089 772.764038,1377.983643 773.031372,1378.836426 
	C763.287354,1378.836426 753.749023,1378.836426 744.042786,1378.836426 
	C744.305298,1378.138916 744.355469,1377.629395 744.536987,1377.577148 
	C752.336182,1375.336792 752.879272,1374.677979 752.893188,1366.201416 
	C752.924927,1346.899292 752.915222,1327.597046 752.881348,1308.294922 
	C752.877258,1305.971069 752.631348,1303.646851 752.477478,1301.324097 
	C752.204956,1297.208740 750.782043,1294.537109 745.900085,1295.832886 
	C745.152588,1296.031250 744.202332,1295.465820 743.089844,1294.093018 
	C748.989441,1291.714355 754.889038,1289.335693 761.209961,1286.912354 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1468.426758,1330.529785 
	C1465.078979,1334.406982 1464.088989,1338.539917 1464.278687,1343.280518 
	C1464.597656,1351.254395 1464.249512,1359.255615 1464.597900,1367.227173 
	C1464.920776,1374.611572 1466.010620,1375.549805 1473.202271,1377.062256 
	C1473.766235,1377.180786 1474.271240,1377.580811 1474.668335,1378.827393 
	C1464.620117,1378.827393 1454.571899,1378.827393 1444.400635,1378.827393 
	C1444.556519,1378.231812 1444.553467,1377.964966 1444.647095,1377.924805 
	C1453.634888,1374.094849 1453.637695,1374.095947 1453.639893,1363.893677 
	C1453.641846,1355.574585 1453.713501,1347.254639 1453.616821,1338.936646 
	C1453.493530,1328.316895 1453.433960,1328.317627 1443.729004,1325.390625 
	C1450.212891,1322.740234 1456.696777,1320.089722 1464.061523,1317.079346 
	C1464.273560,1321.699463 1464.434082,1325.199463 1464.646851,1329.838989 
	C1467.281982,1326.743286 1469.032227,1324.411621 1471.066528,1322.361938 
	C1474.110596,1319.294678 1477.794922,1316.784912 1482.163086,1318.655151 
	C1484.437500,1319.628662 1486.706909,1322.072754 1487.563354,1324.378296 
	C1488.120728,1325.879150 1486.606323,1329.478516 1485.167114,1330.072876 
	C1483.129150,1330.914551 1479.897705,1330.572510 1477.905884,1329.446167 
	C1474.343628,1327.431641 1471.426636,1325.900757 1468.426758,1330.529785 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1025.164307,1321.964722 
	C1025.775391,1321.247925 1026.379395,1319.914795 1026.998779,1319.907593 
	C1035.315552,1319.810181 1043.634399,1319.887085 1051.952271,1319.977173 
	C1052.289917,1319.980835 1052.621582,1320.541016 1053.286255,1321.139160 
	C1052.395508,1321.614380 1051.793213,1322.143677 1051.118652,1322.262329 
	C1045.899170,1323.180176 1045.742310,1326.699463 1047.163696,1330.534302 
	C1049.357300,1336.452759 1051.902588,1342.242188 1054.349609,1348.064819 
	C1056.586304,1353.387207 1058.890625,1358.681274 1061.683105,1365.196289 
	C1066.942017,1351.886597 1071.798340,1339.730347 1076.527344,1327.524658 
	C1077.587280,1324.788818 1076.337036,1323.056152 1073.448364,1322.487793 
	C1072.375977,1322.276733 1071.333008,1321.916016 1070.333740,1320.525513 
	C1076.575806,1320.525513 1082.817871,1320.525513 1089.101929,1320.525513 
	C1089.120850,1320.845947 1089.236938,1321.413452 1089.145020,1321.449463 
	C1082.505249,1324.046631 1080.751221,1330.228027 1078.384155,1335.984863 
	C1072.758545,1349.666626 1067.129272,1363.347168 1061.392822,1376.982544 
	C1060.750610,1378.509155 1059.409180,1379.741333 1058.392090,1381.110107 
	C1057.316040,1379.801025 1055.862915,1378.653564 1055.221924,1377.157959 
	C1048.740112,1362.033691 1042.335693,1346.875610 1036.037109,1331.674194 
	C1034.020386,1326.806763 1031.356201,1322.921143 1025.355225,1322.122803 
	C1025.092896,1321.919189 1025.164307,1321.964722 1025.164307,1321.964722 
z"/>
<path fill="#F7FDF7" opacity="1.000000" stroke="none" 
	d="
M1025.072998,1321.881836 
	C1024.973755,1322.624634 1024.810181,1323.406738 1024.828613,1324.184448 
	C1024.969482,1330.139404 1021.093689,1332.598267 1015.703064,1330.158936 
	C1013.312073,1329.077026 1009.241699,1327.127808 1008.420532,1327.977051 
	C1005.443237,1331.055786 1001.905640,1334.994019 1001.519836,1338.898438 
	C1000.593079,1348.278564 1001.084595,1357.833496 1001.478333,1367.298218 
	C1001.776733,1374.469727 1002.906799,1375.330933 1010.218262,1377.109131 
	C1010.758057,1377.240356 1011.206421,1377.747681 1012.955994,1378.932495 
	C1001.490967,1378.932495 991.455505,1378.932495 981.048035,1378.932495 
	C981.477417,1378.186035 981.622559,1377.557251 981.916565,1377.477661 
	C990.035706,1375.277100 990.437805,1374.843384 990.461365,1366.334717 
	C990.490356,1355.856934 990.563782,1345.375366 990.325745,1334.902100 
	C990.159607,1327.591919 989.574097,1327.260376 982.212463,1327.158569 
	C981.815796,1327.153198 981.424377,1326.766724 979.590942,1325.793335 
	C987.178101,1322.731812 993.687378,1320.105103 1000.977844,1317.163208 
	C1000.977844,1321.825439 1000.977844,1325.409424 1000.977844,1330.026978 
	C1008.196777,1323.640259 1012.975159,1311.535278 1024.802734,1322.107910 
	C1025.164307,1321.964722 1025.092896,1321.919189 1025.072998,1321.881836 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M473.594543,1318.216553 
	C475.544312,1319.172607 477.419861,1319.760742 478.609467,1321.004883 
	C480.944855,1323.447144 481.456726,1326.833008 478.930725,1329.026733 
	C477.355103,1330.395142 474.272369,1330.031982 471.862610,1330.425171 
	C471.735016,1330.446167 471.585358,1330.240112 471.429932,1330.196289 
	C468.771088,1329.446777 464.503723,1327.319702 463.735107,1328.210083 
	C460.856873,1331.544922 457.808319,1335.689697 457.246185,1339.857544 
	C456.231262,1347.382080 456.950348,1355.141602 456.961426,1362.802979 
	C456.978119,1374.307861 456.986328,1374.311157 466.919891,1377.886963 
	C464.416321,1379.995850 441.515259,1380.359009 437.138824,1378.359375 
	C437.171387,1378.196045 437.157440,1377.921021 437.243164,1377.885254 
	C446.037415,1374.219604 446.040283,1374.220703 446.039490,1364.276611 
	C446.038788,1355.282837 446.083679,1346.288818 446.013977,1337.295532 
	C445.939362,1327.673828 445.812958,1327.584106 436.831024,1326.699829 
	C436.647552,1326.267090 436.464081,1325.834229 436.280609,1325.401489 
	C442.828033,1322.708496 449.375488,1320.015625 456.805481,1316.959595 
	C456.805481,1321.856201 456.805481,1325.435181 456.805481,1330.170410 
	C462.075989,1324.637207 465.158051,1317.746826 473.594543,1318.216553 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1104.806030,1341.302490 
	C1104.802368,1340.332764 1104.812134,1339.832642 1104.794067,1339.333496 
	C1104.410400,1328.709717 1104.409790,1328.709717 1094.947021,1325.294556 
	C1101.562622,1322.636841 1108.178223,1319.979004 1115.722656,1316.948120 
	C1115.722656,1328.109131 1115.720947,1337.746338 1115.723267,1347.383423 
	C1115.724609,1353.213379 1115.651855,1359.044800 1115.755249,1364.872925 
	C1115.930664,1374.750122 1115.978149,1374.749268 1124.286499,1378.807983 
	C1114.888550,1378.807983 1105.490601,1378.807983 1095.975464,1378.807983 
	C1096.177856,1378.204346 1096.211914,1377.682983 1096.391724,1377.626709 
	C1104.604736,1375.051636 1104.755859,1374.880249 1104.796753,1365.758789 
	C1104.832642,1357.763550 1104.805786,1349.767944 1104.806030,1341.302490 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M600.903076,1367.726807 
	C600.451843,1374.391479 603.203308,1377.857544 610.520569,1377.454468 
	C610.184265,1377.944702 609.848022,1378.434814 609.511719,1378.925049 
	C600.037964,1378.925049 590.564209,1378.925049 580.607178,1378.925049 
	C581.265686,1378.010742 581.466187,1377.397217 581.821899,1377.287842 
	C589.547546,1374.910400 589.820618,1374.648071 589.874268,1366.859253 
	C589.937317,1357.704956 589.964783,1348.548950 589.867615,1339.395264 
	C589.752625,1328.558105 589.687805,1328.558838 580.097046,1325.320679 
	C586.694031,1322.632324 593.291077,1319.943970 600.878723,1316.851929 
	C600.878723,1334.699463 600.878723,1350.981079 600.903076,1367.726807 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1573.711548,1075.478516 
	C1574.135376,1080.583740 1572.211914,1084.097046 1567.712158,1085.578979 
	C1563.956055,1086.816284 1559.898926,1086.145996 1558.088745,1082.483643 
	C1556.776978,1079.829224 1555.984619,1075.605103 1557.239746,1073.348389 
	C1558.616455,1070.872925 1562.484497,1068.809937 1565.491455,1068.451660 
	C1569.586670,1067.963867 1572.542603,1070.861084 1573.711548,1075.478516 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1299.963135,1068.734497 
	C1306.143799,1067.878174 1309.661743,1069.579346 1311.187256,1074.006470 
	C1312.669556,1078.308594 1311.526245,1081.990112 1307.799316,1084.487305 
	C1304.064453,1086.989990 1300.169556,1086.642456 1296.814819,1083.611450 
	C1292.042114,1079.299316 1293.338257,1072.727661 1299.963135,1068.734497 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M411.504700,1374.751099 
	C411.701630,1370.219849 413.729065,1366.982910 417.985016,1367.249634 
	C420.446198,1367.403931 424.105438,1369.417236 424.790070,1371.424438 
	C425.575928,1373.728271 424.504028,1377.726196 422.755310,1379.612793 
	C419.138000,1383.515137 413.761841,1381.099854 411.504700,1374.751099 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M378.743164,1377.058838 
	C377.229828,1372.825684 378.362854,1369.458984 381.957794,1367.981812 
	C384.024902,1367.132324 387.720398,1367.230957 389.130402,1368.555664 
	C390.917114,1370.234009 391.997009,1373.687012 391.721344,1376.191895 
	C391.525970,1377.967407 388.836121,1380.155640 386.802673,1380.884277 
	C383.569702,1382.042725 380.673615,1380.565796 378.743164,1377.058838 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M346.818237,1379.187500 
	C344.114136,1375.505493 343.651642,1371.701172 347.038513,1369.191040 
	C349.010895,1367.729492 352.971893,1367.034668 354.972260,1368.064575 
	C356.985138,1369.100952 358.779938,1372.726562 358.725342,1375.174316 
	C358.600555,1380.772217 352.886353,1382.659790 346.818237,1379.187500 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M600.578979,1289.510620 
	C602.565491,1292.971191 602.558350,1296.377930 599.509949,1298.188110 
	C597.499329,1299.382080 593.867615,1299.694092 591.943298,1298.628540 
	C590.259827,1297.696045 589.014282,1294.204468 589.215515,1291.992798 
	C589.682495,1286.861084 595.233887,1285.635620 600.578979,1289.510620 
z"/>
<path fill="#F5FDF4" opacity="1.000000" stroke="none" 
	d="
M1114.900635,1297.977905 
	C1111.602783,1300.690430 1108.091431,1300.953247 1105.827759,1297.976440 
	C1104.431030,1296.139526 1103.793945,1292.306152 1104.805420,1290.458008 
	C1105.804932,1288.631714 1109.372803,1287.175415 1111.697632,1287.328247 
	C1116.397583,1287.636963 1117.806396,1292.553833 1114.900635,1297.977905 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M1084.056763,789.000000 
	C1084.056763,739.890625 1084.056763,691.281189 1084.056763,642.757507 
	C1098.280273,639.490234 1106.136353,644.914551 1107.475830,658.606995 
	C1107.654053,660.428894 1107.845215,662.260315 1107.845581,664.087341 
	C1107.857666,710.388184 1107.876709,756.689148 1107.787842,802.989807 
	C1107.778931,807.637695 1107.331421,812.319092 1106.667603,816.924377 
	C1105.159302,827.388306 1101.766724,830.556702 1091.218018,831.783630 
	C1089.092285,832.030945 1086.933350,831.994385 1084.056763,832.120911 
	C1084.056763,817.689270 1084.056763,803.594666 1084.056763,789.000000 
z"/>
<path fill="#4CAC44" opacity="1.000000" stroke="none" 
	d="
M1295.460693,720.469116 
	C1297.403687,739.761475 1299.282349,758.599670 1301.200439,777.833923 
	C1292.659912,777.833923 1284.468140,777.833923 1274.983887,777.833923 
	C1279.654297,738.858337 1284.274902,700.298706 1288.895630,661.739014 
	C1289.366333,661.735535 1289.837158,661.732056 1290.307983,661.728638 
	C1292.004028,681.157349 1293.700073,700.586121 1295.460693,720.469116 
z"/>
<path fill="#4CAC44" opacity="1.000000" stroke="none" 
	d="
M1602.879395,708.788818 
	C1604.958984,692.516479 1606.997803,676.691284 1609.036743,660.866089 
	C1609.259888,660.865112 1609.483032,660.864075 1609.706177,660.863098 
	C1613.380615,699.692078 1617.055054,738.521057 1620.769287,777.771484 
	C1612.495605,777.771484 1604.155151,777.771484 1594.867798,777.771484 
	C1597.548950,754.718933 1600.193848,731.977478 1602.879395,708.788818 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M1443.784424,1152.755127 
	C1448.913940,1150.458984 1453.702759,1148.324097 1459.132568,1145.903564 
	C1459.132568,1156.213501 1459.141479,1165.647217 1459.112793,1175.081055 
	C1459.110962,1175.710693 1458.960938,1176.418457 1458.649170,1176.956055 
	C1456.340088,1180.935547 1444.564331,1186.095459 1439.665771,1185.326538 
	C1434.763916,1184.557251 1430.621216,1180.217407 1429.290771,1174.457642 
	C1427.815308,1168.070435 1430.527222,1160.712036 1436.032715,1156.922607 
	C1438.332642,1155.339478 1440.963501,1154.237305 1443.784424,1152.755127 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M750.708923,1176.162354 
	C744.172546,1162.232056 742.757324,1148.031616 746.009521,1133.483643 
	C748.455994,1122.540405 755.385986,1116.543091 764.687866,1116.567139 
	C773.415344,1116.589722 779.086670,1121.795166 782.726685,1128.823486 
	C790.821411,1144.453369 791.948547,1161.083008 786.716736,1177.732056 
	C782.400818,1191.466309 766.963379,1194.674927 756.841858,1184.427734 
	C754.538696,1182.096069 752.850281,1179.157227 750.708923,1176.162354 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M1619.767334,1179.143066 
	C1611.546021,1164.530273 1609.983398,1149.232910 1613.307861,1133.447388 
	C1615.406372,1123.482178 1621.846436,1117.539917 1630.091064,1116.700073 
	C1638.663696,1115.826904 1646.328125,1120.527100 1650.865723,1130.156738 
	C1657.716797,1144.696167 1658.826782,1159.916016 1654.885620,1175.429077 
	C1653.011353,1182.806763 1648.861206,1188.417725 1640.817505,1189.961304 
	C1632.688965,1191.521240 1626.598633,1188.045776 1621.680664,1181.902832 
	C1621.058472,1181.125610 1620.544067,1180.262329 1619.767334,1179.143066 
z"/>
<path fill="#4CAC44" opacity="1.000000" stroke="none" 
	d="
M594.013977,1138.537598 
	C589.216064,1138.538574 584.914856,1138.538574 580.044006,1138.538574 
	C580.596558,1130.361206 583.656372,1124.027588 590.160950,1119.803711 
	C595.827332,1116.124146 602.098511,1115.512207 608.088135,1119.039307 
	C615.185120,1123.218628 616.695923,1130.251221 617.197327,1138.536743 
	C609.330505,1138.536743 601.920593,1138.536743 594.013977,1138.537598 
z"/>
<path fill="#4CAC44" opacity="1.000000" stroke="none" 
	d="
M457.227173,1121.145020 
	C463.652954,1116.412720 470.315887,1115.180908 477.051331,1119.030518 
	C484.166992,1123.097412 485.796753,1130.145264 486.093567,1138.156860 
	C473.745758,1138.156860 461.734009,1138.156860 448.954254,1138.156860 
	C449.445618,1131.438721 451.945831,1125.869629 457.227173,1121.145020 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M693.202637,1370.021240 
	C692.963074,1358.835205 692.834961,1348.027710 692.888428,1337.221191 
	C692.914429,1331.966797 701.015869,1326.611938 707.435547,1327.496460 
	C714.046570,1328.407104 718.160339,1332.571411 720.594360,1338.415527 
	C723.921326,1346.403809 723.904968,1354.638672 721.277649,1362.806763 
	C716.521912,1377.592285 705.096497,1380.659546 693.202637,1370.021240 
z"/>
<path fill="#4CAC44" opacity="1.000000" stroke="none" 
	d="
M650.996216,1357.927246 
	C651.951904,1364.444946 650.370422,1368.948853 644.136841,1371.383057 
	C640.052063,1372.978271 636.258118,1374.239014 632.572632,1370.900391 
	C628.687378,1367.380615 627.929260,1362.829224 629.569824,1358.176392 
	C630.395508,1355.834717 632.164551,1353.266235 634.239685,1352.035400 
	C639.306030,1349.030151 644.799316,1346.744751 650.996033,1343.745972 
	C650.996033,1348.981689 650.996033,1353.213745 650.996216,1357.927246 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M1417.794678,1327.160645 
	C1426.842163,1339.531006 1428.092163,1352.973022 1423.954468,1366.835205 
	C1420.680542,1377.803833 1409.045044,1379.963257 1401.151855,1371.606934 
	C1392.504639,1362.452393 1389.942017,1340.112183 1396.280518,1329.139526 
	C1400.832397,1321.259766 1409.758301,1320.324951 1417.794678,1327.160645 
z"/>
<path fill="#49AB42" opacity="1.000000" stroke="none" 
	d="
M1610.173340,1368.744629 
	C1604.066528,1358.148926 1602.680786,1347.119995 1605.050049,1335.651733 
	C1606.313354,1329.537231 1609.080200,1324.242310 1616.001709,1322.839233 
	C1622.339600,1321.554321 1627.985718,1324.095459 1631.733521,1330.877808 
	C1638.360718,1342.870972 1639.233032,1355.769287 1634.603027,1368.491455 
	C1630.710205,1379.187622 1618.275024,1379.218262 1610.173340,1368.744629 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M1220.121582,1323.307617 
	C1226.730347,1326.149902 1228.295654,1331.507324 1228.815552,1338.077881 
	C1219.765137,1338.077881 1210.961060,1338.077881 1202.158325,1338.077881 
	C1201.789307,1328.073975 1209.058960,1321.987061 1220.121582,1323.307617 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M794.613037,1338.551270 
	C790.929688,1338.952148 790.104492,1337.625122 790.903503,1334.508789 
	C792.826172,1327.010376 799.320496,1322.113281 806.673401,1322.868286 
	C813.322266,1323.551025 817.299255,1329.154053 817.261047,1338.552490 
	C809.873047,1338.552490 802.458435,1338.552490 794.613037,1338.551270 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M940.310669,1338.552124 
	C938.379761,1338.477905 936.909729,1338.404663 935.473145,1338.333130 
	C934.916077,1329.588989 941.536743,1322.608643 949.917419,1322.825684 
	C957.509583,1323.022461 962.487793,1329.491089 961.554504,1338.553101 
	C954.670593,1338.553101 947.721008,1338.553101 940.310669,1338.552124 
z"/>
<path fill="#52AF4A" opacity="1.000000" stroke="none" 
	d="
M512.892822,1338.550537 
	C506.466980,1338.549927 500.524689,1338.549927 494.596466,1338.549927 
	C494.553894,1329.385254 500.640930,1322.807739 508.674133,1322.823975 
	C516.433655,1322.839600 520.996704,1328.303711 520.991394,1338.551147 
	C518.481201,1338.551147 515.928711,1338.551147 512.892822,1338.550537 
z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
